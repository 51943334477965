import React from 'react';

const NotificationPage = () => {
    return (
        <div>
            <h1>Notificación de pago</h1>
            <p>Aquí se mostrará la información de la notificación de pago.</p>
        </div>
    );
};

export default NotificationPage;
