import React from "react";
import { motion } from "framer-motion";

function AddToCartButton({ onClick, product }) {
  return (
    <motion.button
      className="rounded-[10px] border-[1px] border-main-purple p-[9px] cursor-pointer bg-white"
      whileTap={{ scale: 0.95, borderColor: "#44045E" }}
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
    >
      <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-main-purple to-[#640A88]">
        {product.stockLevel === 0 ? "Producto sin stock" : "Agregar al carrito"}
      </p>
    </motion.button>
  );
}

export default AddToCartButton;
