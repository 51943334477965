import React, { useState, useEffect } from "react";
import UseWindowDimensions from "../utils/UseWindowDimensions";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import Shop from "../components/Shop";
import { UilAngleRight } from "@iconscout/react-unicons";
import BottomCart from "../components/BottomCart";
import { useCartContext } from "../context/CartProvider";
import CheckoutAlert from "../components/alerts/CheckoutAlert";

function Tiendas() {
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  const [card, setCard] = useState(false);
  const navigate = useNavigate();
  const [isCartShown, setIsCartShown] = useState(false);
  const { setIsCartActive, stores, isLoaded, isAlertAdded, setIsAlertAdded } =
    useCartContext();

  window.onscroll = function () {
    let navBar = document.getElementById("navBar");
    let rect = navBar.getBoundingClientRect();
    let menuTop = rect.top;

    if (menuTop < -100 && desktopWidth) {
      setIsCartShown(true);
    } else {
      setIsCartShown(false);
    }
  };

  const handleCart = () => {
    setIsCartActive(true);
  };

  if (isLoaded === true) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        exit={{ opacity: 0 }}
      >
        <BottomCart
          setIsCartShown={setIsCartShown}
          isCartShown={isCartShown}
          onClick={handleCart}
        />
        <NavBar />
        <motion.div
          className="py-[40px] px-[16px] md:px-[50px] lg:px-[100px] space-y-[100px]"
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", bounce: 0.4, delay: 1 }}
        >
          {stores.map((store, index) => (
            <Shop
              store={store}
              index={index}
              key={store.id}
              currency={store.currency.code}
            />
          ))}

          {/* Restaurant card */}
          {/* <span className="fixed top-[15%] left-[10px] text-main-purple">
            Menú
          </span> */}
          <div
            className={`fixed top-[20%] p-[50px] space-y-[20px] rounded-br-[12px] rounded-tr-[12px] shadow-lg w-[230px] h-auto bg-main-purple duration-1000 ${
              card === true
                ? "left-[0vw]"
                : desktopWidth
                ? "left-[-12vw]"
                : "left-[-30vw]"
            }`}
          >
            <h4 className="text-white text-xl">Restaurantes</h4>
            <ul className="text-secondary-purple text-sm space-y-[10px]">
              {stores.map((store) => (
                <li
                  className="cursor-pointer border-b-[1px] border-black/50"
                  key={store.id}
                  onClick={() => navigate(`/perfil/${store.link}`)}
                >
                  {store.name}
                </li>
              ))}
            </ul>
            {card === false && (
              <span className="absolute top-[-50%] bottom-0 right-[10px] h-full flex items-center cursor-pointer text-white">
                Menú
              </span>
            )}
            <div
              className="absolute top-[-5%] bottom-0 left-[90%] h-full flex items-center cursor-pointer"
              onClick={() => setCard(!card)}
            >
              <UilAngleRight
                className={`${card && "rotate-180"} text-secondary-purple`}
              />
            </div>
          </div>
        </motion.div>
        <Footer />
        <CheckoutAlert
          setIsAlertOpened={setIsAlertAdded}
          isAlertOpened={isAlertAdded}
        />
      </motion.div>
    );
  } else {
    return (
      <div className="w-full h-[100vh] cursor-progress">
        <div className="absolute bottom-0 top-0 right-0 left-0 m-auto w-[50px] h-[50px]">
          <span className="loader"></span>
        </div>
      </div>
    );
  }
}

export default Tiendas;
