import React, { useState, useEffect } from "react";
import {
  UilInstagram,
  UilFacebook,
  UilLinkedin,
  UilApple,
  UilAndroid,
  UilMessage,
} from "@iconscout/react-unicons";
import pagoTiendaLogo from "../assets/images/pagoTiendaLogo.svg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { goToAnotherPage } from "../routes/goToAnotherPage";
import { Formik } from "formik";
import * as yup from "yup";

function Footer() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const validation = yup.object({
    email: yup.string().required().email(),
  });
  const hover = {
    hover: { scale: 1.1, color: "#640A88" },
  };
  useEffect(() => {
    if (message !== "") {
      const timeoutId = setTimeout(() => {
        setMessage("");
      }, 8000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [message]);

  const handleSubmit = () => {
    fetch(`https://services.pagotienda.com/newsletter/subscribe`, {
      method: "POST",
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((resJSON) => {
        setMessage("Por favor confirme su correo en la bandeja de entrada 😄");
        setEmail("");
      });
  };
  return (
    <div className="bg-dark-gray text-gray py-[40px] px-[16px] md:px-[50px] lg:px-[100px] flex flex-col items-center space-y-[70px] selection:bg-secondary-purple">
      <div className="grid md:grid-cols-3 m-auto justify-center space-y-[50px]">
        <div className="w-full flex justify-center">
          <img
            src={pagoTiendaLogo}
            alt="Pago Tienda Logo"
            onClick={() => goToAnotherPage(navigate, "/")}
            className="cursor-pointer"
          />
        </div>
        <div className="flex space-x-[50px] justify-center">
          <div>
            <h4 className="text-white text-xl">Menú</h4>
            <p
              className="text-sm cursor-pointer"
              onClick={() => goToAnotherPage(navigate, "/")}
            >
              Inicio
            </p>
            <p
              className="text-sm cursor-pointer"
              onClick={() => goToAnotherPage(navigate, "/tiendas")}
            >
              Tiendas
            </p>
            <p
              className="text-sm cursor-pointer"
              onClick={() => goToAnotherPage(navigate, "/aliados")}
            >
              Aliados
            </p>
            <p
              className="text-sm cursor-pointer"
              onClick={() => goToAnotherPage(navigate, "/nosotros")}
            >
              Nosotros
            </p>
          </div>
          <div className="flex flex-col">
            <h4 className="text-white text-xl">Contacto</h4>
            <a className="text-sm cursor-pointer" href="tel:+5491152209217">
              +54 9 11 5220-9217
            </a>
            <a className="text-sm cursor-pointer" href="info@pagotienda.com">
              info@pagotienda.com
            </a>
          </div>
        </div>
        <div className="space-y-[30px] flex flex-col">
          <div className="space-y-[10px]">
            <h4 className="text-white text-xl text-center md:text-left">
              Suscríbete a nuestro Newsletter
            </h4>
            <p className="text-base">
              Recibí ofertas exclusivas y consejos personalizados para comprar y
              vender en Pago Tienda.
            </p>
          </div>
          <div className="w-full h-[40px] flex space-x-[7px]">
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validation}
              onSubmit={() => handleSubmit()}
            >
              {(formikprops) => (
                <>
                  <input
                    className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-light-gray focus-visible:outline-main-purple"
                    placeholder="Introducí tu email"
                    value={email}
                    onChange={(e) => {
                      const value = e.target.value;
                      setEmail(value);
                      formikprops.values.email = value;
                    }}
                  />
                  <motion.button
                    className="bg-main-purple w-[40px] flex justify-center items-center rounded-[10px]"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95, backgroundColor: "#44045E" }}
                    onClick={formikprops.handleSubmit}
                    type="submit"
                    aria-label="subscribe newsletter"
                  >
                    <UilMessage color="#FFFFFF" />
                  </motion.button>
                </>
              )}
            </Formik>
          </div>
          {message !== "" && (
            <span className="text-white bg-[#155724]/50 p-[16px] ">
              {message}
            </span>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center w-full pt-[15px] border-t-gray border-t-[1px] space-x-[10px] md:justify-start text-gray">
        <motion.a
          whileHover={"hover"}
          variants={hover}
          href="https://www.instagram.com/pago_tienda/?hl=es"
          target="_blank"
          aria-label="Pago Tienda Instagram"
        >
          <UilInstagram size="20" className="cursor-pointer" variants={hover} />
        </motion.a>
        <motion.a
          whileHover={"hover"}
          variants={hover}
          href="https://www.facebook.com/Pagotienda"
          target="_blank"
          aria-label="Pago Tienda Facebook"
        >
          <UilFacebook size="20" className="cursor-pointer" />
        </motion.a>
        <motion.a
          whileHover={"hover"}
          variants={hover}
          href="https://www.linkedin.com/company/pagolinea/"
          target="_blank"
          aria-label="Pago Tienda Linkedin"
        >
          <UilLinkedin size="20" className="cursor-pointer" />
        </motion.a>
        <motion.a
          whileHover={"hover"}
          variants={hover}
          href="https://apps.apple.com/es/app/pagotienda/id1603128257"
          target="_blank"
          aria-label="Apple Store App"
        >
          <UilApple size="20" className="cursor-pointer" />
        </motion.a>
        <motion.a
          whileHover={"hover"}
          variants={hover}
          href="https://play.google.com/store/apps/details?id=com.pagolinea.Pagotienda"
          target="_blank"
          aria-label="Google Play App"
        >
          <UilAndroid size="20" className="cursor-pointer" />
        </motion.a>
      </div>
    </div>
  );
}
export default Footer;
