import React from "react";
import {
  UilShoppingCart,
  UilBitcoinCircle,
  UilGift,
} from "@iconscout/react-unicons";

function InstructionCard({ icon, title, description }) {
  const icons = [
    <UilShoppingCart size="50" />,
    <UilBitcoinCircle size="50" />,
    <UilGift size="50" />,
  ];
  return (
    <div className="flex flex-col items-center text-white space-y-[20px]">
      <div className="h-[110px] flex flex-col items-center">
        {icons[icon]}
        <h3 className="font-medium text-2xl text-center">{title}</h3>
      </div>
      <p className="font-normal text-base text-left text-[#CF99E5]">
        {description}
      </p>
    </div>
  );
}

export default InstructionCard;
