import React from "react";

function UserBasicInfo({ title, user, email = false }) {
  const fullName = user.firstName + " " + user.lastName;
  return (
    <div className="flex flex-col justify-start">
      <h3 className="text-main-purple text-xl lg:text-2xl">{title}</h3>
      <p className="text-black">{email ? user.email : fullName}</p>
    </div>
  );
}

export default UserBasicInfo;
