import React from "react";
import Carrousel from "../Carrousel";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const images = ["burger.svg", "plate.svg", "drink.svg"];
const textAnimation = {
  offScreen: { y: 50, opacity: 0 },
  onScreen: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", bounce: 0.4, damping: 20 },
  },
};
function Hero() {
  const navigate = useNavigate();

  return (
    <div className="p-[16px] md:p-[50px] lg:p-[100px]  flex flex-col md:flex-row">
      <Carrousel
        images={images}
        autoPlay={true}
        isAlone={false}
        duration="5000"
      />

      <div className="space-y-[60px] text-center md:order-1 flex flex-col items-center md:items-start md:justify-center md:w-[60%] lg:w-[50%]">
        <motion.div
          className="text-black text-center space-y-[30px] md:text-left md:mr-[5%]"
          initial={"offScreen"}
          animate={"onScreen"}
          transition={{ staggerChildren: 0.2, delayChildren: 1 }}
        >
          <motion.h1
            className="font-bold text-4xl leading-[45px] md:text-4xl lg:text-6xl lg:leading-[65px]"
            variants={textAnimation}
          >
            ¡Pedí tu <span className="text-main-purple">comida favortia!</span>
          </motion.h1>
          <motion.p className="text-xl" variants={textAnimation}>
            Aprovechá, tenemos las mejores ofertas de la ciudad
          </motion.p>
          <motion.button
            className="p-[10px] bg-main-purple rounded-xl text-white w-[30%]"
            variants={textAnimation}
            whileHover={{
              backgroundColor: "#640A88",
              scale: 1.1,
              transition: { type: "spring", bounce: 0.6 },
            }}
            onClick={() => navigate("/tiendas")}
          >
            Menú
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
}

export default Hero;
