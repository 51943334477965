import React from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "../context/CartProvider";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { motion } from "framer-motion";

function SuccessPopUp({ isSuccessPopUpActive, setIsSuccessPopUpActive }) {
  const { setOrderNumber, orderNumber, setItemsInCart } = useCartContext();
  const navigate = useNavigate();

  const close = () => {
    setIsSuccessPopUpActive(false);
    setItemsInCart([]);
    navigate("/tiendas");
    window.location.reload();
  };
  return (
    <div
      className={`bg-black/50 absolute flex inset-0 m-auto justify-center items-center cursor-pointer ${
        isSuccessPopUpActive === true
          ? "w-[100%] h-[100%] opacity-100 duration-[2s]"
          : "w-0 h-0 opacity-0 duration-[2s]"
      }`}
      onClick={() => close()}
    >
      <div
        className={`bg-white rounded-xl shadow-md w-[90%] lg:w-[40%] h-[50%] p-[16px]  flex flex-col justify-between cursor-default ${
          isSuccessPopUpActive === true
            ? "opacity-100 duration-500"
            : "opacity-0 duration-500"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-end">
          <IoMdClose
            className="text-xl text-light-gray cursor-pointer"
            onClick={() => close()}
          />
        </div>
        <div className="flex justify-center items-center flex-col space-y-[10px]">
          <BsFillCheckCircleFill className="text-main-purple text-[100px]" />
          <h3 className="text-black text-2xl">Tu orden ha sido confirmada</h3>
          <span className="text-black">
            Tu número de orden es: {orderNumber}
          </span>
        </div>
        <div className="flex justify-center">
          <motion.a
            href={`https://admin.pagotienda.com/buyer/order/${orderNumber}`}
            target="_blank"
            rel="noreferrer"
            className="text-black "
          >
            Puedes hacer el seguimiento a tu pedido{" "}
            <motion.span
              className="text-main-purple text-xl"
              initial={{ fontSize: "20px" }}
              animate={{ fontSize: "23px" }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: "mirror",
              }}
            >
              Aquí
            </motion.span>
          </motion.a>
        </div>
      </div>
    </div>
  );
}

export default SuccessPopUp;
