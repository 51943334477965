import { motion } from "framer-motion";
import React, { useEffect } from "react";
import UseWindowDimensions from "../utils/UseWindowDimensions";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";

function ContactCards({ icon, text, isActive, setIsActive, onClick }) {
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  const pathAnimation = {
    hidden: { pathLength: 0 },
    visible: {
      pathLength: 1,
    },
  };

  return (
    <div className="flex flex-col items-center h-auto" onClick={onClick}>
      <motion.div
        className="bg-secondary-purple w-full p-[20px] flex flex-col justify-center items-center space-y-[20px] rounded-[20px] cursor-pointer pb-[40px] relative"
        whileHover={{
          scale: 1.05,
          boxShadow: "rgba(99, 11, 134, 0.05) 0px 8px 24px",
        }}
      >
        {icon}
        <p className="text-black text-center">{text}</p>
        <motion.div
          initial={{ bottom: "10px" }}
          animate={{ bottom: "0px" }}
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatType: "mirror",
          }}
          className="absolute bottom-0"
        >
          <HiOutlineArrowNarrowDown className="text-main-purple text-xl" />
        </motion.div>
      </motion.div>

      {desktopWidth && (
        <svg
          width={isActive === true ? "97" : "0"}
          height={isActive === true ? "258" : "0"}
          viewBox={isActive === true ? "0 0 97 258" : "0 0 0 0"}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            d="M53.0005 2.5C28.6671 17.6667 -14.0995 50.6 9.50047 61C39.0005 74 65.5 31 90 46C114.5 61 32 107 33 135.5C34 164 45 215 39 215.5C33 216 17 209 20.5 219C24 229 36.5 258.5 39 255.5C41.5 252.5 63.5 215.5 56.5 215.5C50.9 215.5 42.5 235.5 39 245.5L36 219"
            stroke="#960FCC"
            strokeWidth="4"
            strokeLinejoin="round"
            strokeLinecap="round"
            initial={isActive === true ? "hidden" : "visible"}
            animate={isActive === true ? "visible" : "hidden"}
            transition={
              isActive === true
                ? {
                    duration: 3,
                    ease: "easeInOut",
                  }
                : {
                    duration: 0.01,
                    ease: "easeInOut",
                  }
            }
            variants={pathAnimation}
          />
        </svg>
      )}
    </div>
  );
}

export default ContactCards;
