import React from "react";
import { motion } from "framer-motion";

function SignatureAnimated() {
  const pathAnimation = {
    hidden: { pathLength: 0 },
    visible: {
      pathLength: 1,
    },
  };
  return (
    <svg
      width="253"
      height="73"
      viewBox="0 0 253 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M53.4905 3.16718C61.0936 6.85139 79.4616 11.5759 92.1084 1C82.8491 4.53973 59.9042 17.1672 42.1987 39.3591C24.4932 61.5511 8.02228 69.6997 2 71C4.93586 55.1796 17.5375 23.5387 44.4571 23.5387C48.4469 24.6945 57.8266 26.3127 63.4273 23.5387L61.3948 27.0062C62.5239 26.2838 65.1888 25.0557 66.8148 25.9226C68.8473 27.0062 67.4923 31.3406 69.5248 29.1734C71.1509 27.4396 82.548 9.66873 88.0434 1C79.3863 13.1362 65.9566 36.8885 81.4941 34.8081C100.916 32.2074 101.142 32.6409 104.755 27.0062C108.369 21.3715 118.531 19.4211 121.467 17.4706C124.403 15.5201 130.049 12.0526 137.276 15.5201C144.502 18.9876 222.641 45.2105 252 46.7276"
        stroke="#3E3E3E"
        strokeWidth="2"
        strokeLinecap="round"
        initial={"hidden"}
        animate={"visible"}
        transition={{
          duration: 4,
          ease: "easeInOut",
          delay: 2.2,
        }}
        variants={pathAnimation}
      />
    </svg>
  );
}

export default SignatureAnimated;
