import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaOpencart } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import logo from "../assets/images/logo.svg";
import { motion } from "framer-motion";
import menuIcon from "../assets/images/menuIcon.svg";
import SignInModal from "./SignInModal";
import ShoppingCart from "./ShoppingCart";
import { useCartContext } from "../context/CartProvider";
import UseWindowDimensions from "../utils/UseWindowDimensions";
import Alert from "./alerts/Alert";
import { goToAnotherPage } from "../routes/goToAnotherPage";

function NavBar() {
  const navigate = useNavigate();
  const [nav, setNav] = useState(false);
  const [isSignInActive, setIsSignInActive] = useState(false);
  const {
    itemsInCart,
    isAlertOpened,
    setIsAlertOpened,
    isCartActive,
    setIsCartActive,
  } = useCartContext();
  const [userToken, setUserToken] = useState(null);
  const handleCart = () => {
    setIsCartActive(!isCartActive);
  };
  const handleSignIn = () => {
    setIsSignInActive(!isCartActive);
  };
  const handleNav = () => {
    setNav(!nav);
  };
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;

  useEffect(() => {
    if (localStorage.getItem("user-tk")) {
      const tokenLS = localStorage.getItem("user-tk");
      setUserToken(tokenLS);
    } else {
      setUserToken(null);
    }
  }, []);

  const bodyStyle = document.body.style;
  if (nav === true || (isCartActive === true && width >= 764)) {
    bodyStyle.overflowY = "hidden";
  } else {
    bodyStyle.overflowY = "auto";
  }
  const liAnimation = {
    offScreen: { y: 50, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4 },
    },
  };

  const liAnimationMobile = {
    offScreen: { opacity: 0 },
    onScreen: {
      opacity: 1,
      transition: { damping: 1.5, duration: 0.5 },
    },
  };

  const signOut = () => {
    localStorage.removeItem("user-tk");
    navigate("/tiendas");
    window.location.reload();
  };

  return (
    <>
      <div
        className="lg:block flex-1 lg:px-[100px] lg:pt-[30px] relative z-[100]"
        id="navBar"
      >
        <motion.ul
          className="hidden lg:flex flex-row items-center justify-between"
          initial={"offScreen"}
          animate={"onScreen"}
          transition={{ staggerChildren: 1 }}
        >
          <motion.li
            className="cursor-pointer"
            onClick={() => goToAnotherPage(navigate, "/")}
            variants={liAnimation}
          >
            <motion.img src={logo} alt="Logo svg" whileHover={{ rotate: 10 }} />
          </motion.li>

          <motion.ul
            className="flex flex-row items-center space-x-[40px] text-base text-black font-normal"
            initial={"offScreen"}
            animate={"onScreen"}
            transition={{ staggerChildren: 0.2 }}
          >
            <motion.li
              onClick={() => goToAnotherPage(navigate, "/")}
              variants={liAnimation}
              className="cursor-pointer"
              whileHover={{ color: "#640A88", rotate: 5 }}
            >
              Inicio
            </motion.li>
            <motion.li
              onClick={() => goToAnotherPage(navigate, "/tiendas")}
              variants={liAnimation}
              className="cursor-pointer"
              whileHover={{ color: "#640A88", rotate: 5 }}
            >
              Tiendas
            </motion.li>
            <motion.li
              onClick={() => goToAnotherPage(navigate, "/aliados")}
              variants={liAnimation}
              className="cursor-pointer"
              whileHover={{ color: "#640A88", rotate: 5 }}
            >
              Aliados
            </motion.li>
            <motion.li
              onClick={() => goToAnotherPage(navigate, "/nosotros")}
              variants={liAnimation}
              className="cursor-pointer"
              whileHover={{ color: "#640A88", rotate: 5 }}
            >
              Nosotros
            </motion.li>
            {userToken !== null ? (
              <>
                <motion.li
                  onClick={() => goToAnotherPage(navigate, "/usuario")}
                  variants={liAnimation}
                  className="cursor-pointer"
                  whileHover={{ color: "#640A88", rotate: -5 }}
                >
                  Mis Datos
                </motion.li>

                <motion.li
                  onClick={signOut}
                  variants={liAnimation}
                  className="cursor-pointer"
                  whileHover={{ color: "#640A88", rotate: 5 }}
                >
                  logout
                </motion.li>
              </>
            ) : (
              <motion.li
                className="rounded-[10px] border-[1px] border-main-purple p-[9px] cursor-pointer"
                onClick={handleSignIn}
                variants={liAnimation}
                whileTap={{ scale: 0.9 }}
                whileHover={{ rotate: -5 }}
              >
                <p className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-main-purple to-[#640A88]">
                  Iniciar Sesión
                </p>
              </motion.li>
            )}
          </motion.ul>

          <motion.li variants={liAnimation} onClick={() => handleCart()}>
            <motion.div
              className={`flex flex-row items-center space-x-[10px] cursor-pointer text-main-purple`}
              whileHover={{ color: "#640A88" }}
            >
              <FaOpencart size={40} />
              <p>{itemsInCart.length}</p>
            </motion.div>
          </motion.li>
        </motion.ul>

        {/* MOBILE */}

        <div
          onClick={handleNav}
          className="fixed lg:hidden right-[16px] top-[16px] md:right-[50px] md:top-[50px] bg-white rounded-full w-[50px] h-[50px] grid place-content-center"
        >
          {!nav ? (
            <img src={menuIcon} alt="Menu icon" />
          ) : (
            <IoMdClose size={30} color="#960fcc" />
          )}
          {/*Empieza en false, luego al darle click se vuelve el valor contrario en el primer caso true */}
        </div>
        <div
          className={`bg-main-purple fixed top-0 w-[80%] h-full ease-in-out px-[16px] py-[50px] ${
            !nav ? "left-[-100%] duration-200" : "left-0 duration-500"
          }`}
        >
          <motion.ul
            className="lg:hidden flex flex-col justify-between space-y-[40px] h-full"
            animate={nav ? "onScreen" : "offScreen"}
            initial={nav ? "offScreen" : "offScreen"}
            transition={{ staggerChildren: 1 }}
          >
            <motion.ul
              className="flex flex-col text-xl text-white space-y-[20px]"
              animate={nav ? "onScreen" : "offScreen"}
              initial={nav ? "offScreen" : "offScreen"}
              transition={{ staggerChildren: 0.1 }}
            >
              <motion.li
                className="cursor-pointer"
                onClick={() => {
                  goToAnotherPage(navigate, "/");
                }}
                variants={liAnimationMobile}
              >
                Inicio
              </motion.li>
              <motion.li
                className="cursor-pointer"
                onClick={() => {
                  goToAnotherPage(navigate, "/tiendas");
                  window.scrollTo(0, 0);
                }}
                variants={liAnimationMobile}
              >
                Tiendas
              </motion.li>
              <motion.li
                className="cursor-pointer"
                onClick={() => {
                  goToAnotherPage(navigate, "/aliados");
                  window.scrollTo(0, 0);
                }}
                variants={liAnimationMobile}
              >
                Aliados
              </motion.li>
              <motion.li
                className="cursor-pointer"
                onClick={() => {
                  goToAnotherPage(navigate, "/nosotros");
                  window.scrollTo(0, 0);
                }}
                variants={liAnimationMobile}
              >
                Nosotros
              </motion.li>
            </motion.ul>
            <motion.div
              className="space-y-[50px]"
              animate={nav ? "onScreen" : "offScreen"}
              initial={nav ? "offScreen" : "offScreen"}
              transition={{ staggerChildren: 0.5 }}
            >
              {userToken !== null ? (
                <motion.li
                  className="cursor-pointer text-xl text-white"
                  onClick={() => {
                    goToAnotherPage(navigate, "/usuario");
                    window.scrollTo(0, 0);
                  }}
                  variants={liAnimationMobile}
                >
                  Mis datos
                </motion.li>
              ) : (
                <motion.li
                  className="cursor-pointer rounded-lg border-[1px] border-secondary-purple text-center text-secondary-purple py-[10px]"
                  onClick={handleSignIn}
                  variants={liAnimationMobile}
                >
                  Iniciar Sesión
                </motion.li>
              )}
              <motion.li
                className="flex flex-row items-center space-x-[10px]"
                variants={liAnimationMobile}
                onClick={handleCart}
              >
                <div className="flex items-center space-x-[15px]">
                  <FaOpencart size={40} color={"#F7EEFB"} />
                  <p className="text-secondary-purple">{itemsInCart.length}</p>
                </div>
              </motion.li>
            </motion.div>
          </motion.ul>
        </div>
      </div>

      {/* Carrito de compras */}
      <ShoppingCart
        setIsCartActive={setIsCartActive}
        isCartActive={isCartActive}
      />
      {/* Login Modal */}
      <SignInModal
        setIsSignInActive={setIsSignInActive}
        isSignInActive={isSignInActive}
      />
      <Alert isAlertOpened={(isAlertOpened, setIsAlertOpened)} />
    </>
  );
}

export default NavBar;
