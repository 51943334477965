import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import logo from "../assets/images/bigLogo.svg";
import TopTextAnimated from "../components/TopTextAnimated";
import SignatureAnimated from "../components/SignatureAnimated";
import { motion } from "framer-motion";

function Nosotros() {
  const contentAnimation = {
    offScreen: { y: 100, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, delay: 2 },
    },
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0 }}
    >
      <div className="md:h-[100vh] relative">
        <NavBar />
        <div className="py-[40px] px-[16px] md:px-[50px] lg:px-[100px] space-y-[60px] ">
          <TopTextAnimated
            title={"Conocé sobre nosotros"}
            text={"Hacé parte de nuestra familia"}
          />

          <motion.div
            className="grid md:grid-cols-2 place-content-center items-center justify-center space-y-[40px] md:space-y-0 md:justify-between"
            initial={"offScreen"}
            animate={"onScreen"}
            variants={contentAnimation}
          >
            <div className="w-[100%] grid place-content-center md:w-[90%]">
              <img src={logo} alt="dog" />
            </div>
            <div className="space-y-[50px] ">
              <div className="space-y-[20px]">
                <h4 className="text-medium text-2xl md:text-4xl text-main-purple text-center">
                  ¿Qué es Pago Tienda?
                </h4>
                <p className="text-black md:text-xl">
                  Pago Tienda es una tienda virtual para comercios, vinculada a
                  la billetera de Pago Línea con medios de pago seguros, fáciles
                  y efectivos.
                </p>
              </div>
              <div className="space-y-[10px]">
                <p className="text-black text-xl">Daniela, CEO</p>
                <SignatureAnimated />
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      <Footer />
    </motion.div>
  );
}

export default Nosotros;
