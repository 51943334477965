import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosCloseCircle } from "react-icons/io";
import { HiOutlineTrash, HiOutlinePlus, HiMinus } from "react-icons/hi";
import noImg from "../assets/images/noImage.svg";
import { useCartContext } from "../context/CartProvider";
import { FaOpencart } from "react-icons/fa";
import { motion } from "framer-motion";

function ShoppingCart({ isCartActive, setIsCartActive }) {
  const navigate = useNavigate();
  const { setItemsInCart, itemsInCart, setPrice, price } = useCartContext();
  /* *****  Hanlde cart view  ****** */
  const handleCart = () => {
    setIsCartActive(!isCartActive);
  };

  const increaseQty = (product) => {
    let auxArray = itemsInCart.map((item) => {
      if (item === product) {
        if (item.qty < item.stockLevel) {
          item.qty += 1;
        }
      }
      return item;
    });

    setItemsInCart(auxArray);
  };

  const decreaseQty = (product) => {
    const newProduct = JSON.parse(JSON.stringify(product));
    newProduct.qty -= 1;

    if (newProduct.qty <= 0) {
      removeOneFromCart(product);
    } else {
      let auxArray = itemsInCart.map((item) => {
        if (item === product) {
          item.qty -= 1;
        }
        return item;
      });

      setItemsInCart(auxArray);
    }
  };

  const handlePrice = () => {
    let answer = 0;

    itemsInCart.map((item) => (answer += item.totalPrice * item.qty));

    setPrice(answer);
  };

  useEffect(() => {
    handlePrice();
  });

  const removeOneFromCart = (productRemoved) => {
    setItemsInCart(itemsInCart.filter((product) => product !== productRemoved));
    handlePrice();
  };

  const removeAllFromCart = () => {
    if (itemsInCart.length) {
      setItemsInCart([]);
      handleCart();
    }
  };

  const ProductShoppingCartCard = ({ product }) => {
    return (
      <div className="bg-white flex w-full justify-between items-center p-[16px] lg:px-[50px]">
        <div className="flex space-x-[15px]">
          <div className="w-[100px] h-[100px]">
            <img
              src={product.image ? product.image.url : noImg}
              alt="Pago Tienda"
              className="w-full h-full rounded-xl min-w-[100px]"
            />
          </div>
          <div className="">
            <p className="text-black">
              {product.name}{" "}
              {product.activeAdditions.map((item) => (
                <span className="text-light-gray text-[8px]">
                  {" "}
                  {item[0].addition.name + " "}
                </span>
              ))}
            </p>
            <span className="text-light-gray text-sm">
              {product.totalPrice}
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-[10px]">
          <div className="flex w-[100px] justify-between items-center border-[1px] border-light-gray h-[50px] rounded-xl px-[10px]">
            {product.qty === 1 ? (
              <HiOutlineTrash
                className="text-light-gray cursor-pointer"
                onClick={() => decreaseQty(product)}
              />
            ) : (
              <HiMinus
                className="text-light-gray cursor-pointer"
                onClick={() => decreaseQty(product)}
              />
            )}
            <span className="text-black select-none">{product.qty}</span>
            <HiOutlinePlus
              className="text-light-gray cursor-pointer"
              onClick={() => increaseQty(product)}
            />
          </div>
          <IoIosCloseCircle
            className="text-gray cursor-pointer"
            onClick={() => removeOneFromCart(product)}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`fixed w-full h-full bg-black/80 top-0  z-[1400] ease-in-out ${
        isCartActive ? "right-0 duration-500" : "right-[-100%] duration-200"
      }`}
      onClick={handleCart}
    >
      <div
        className={`bg-[#F3F4F6] w-full h-full lg:w-[40%] fixed right-0 flex-1 space-y-[16px] flex flex-col ${
          isCartActive ? "right-0 duration-700" : "right-[-100%] duration-200"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex-[0.1] bg-white w-full flex justify-between items-center p-[16px] lg:px-[50px]">
          <h3 className="text-black text-2xl lg:text-3xl">Tu carrito</h3>
          <IoIosCloseCircle
            className="text-gray text-2xl lg:text-3xl cursor-pointer"
            onClick={handleCart}
          />
        </div>
        <div
          className={`flex-[0.7] w-full overflow-y-scroll flex flex-col space-y-[5px] ${
            itemsInCart.length > 0 ? null : "justify-center items-center"
          }`}
        >
          {itemsInCart.length > 0 ? (
            itemsInCart.map((product, i) => (
              <ProductShoppingCartCard product={product} key={i} />
            ))
          ) : (
            <div className="flex items-center flex-col space-y-[20px]">
              <FaOpencart className="text-main-purple text-5xl" />
              <p>¡Tu carrito aún está vacio!</p>
            </div>
          )}
        </div>
        <div className="flex-[0.2] w-full space-y-[16px] flex flex-col justify-between">
          <div className="bg-white w-full flex justify-center p-[16px] lg:px-[50px]">
            <motion.button
              className="bg-main-purple w-full py-[10px] text-white font-semibold rounded-tl-xl rounded-br-xl space-x-[20px]"
              whileHover={{ backgroundColor: "#44045E" }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate("/pedido")}
              disabled={itemsInCart.length ? false : true}
            >
              <span>Ir a pagar</span>
              <span className="font-normal">{price}</span>
            </motion.button>
          </div>
          <div className="bg-white p-[16px] lg:px-[50px] flex justify-center">
            <div
              className="flex items-center space-x-[10px] cursor-pointer hover:text-gray"
              onClick={removeAllFromCart}
            >
              <HiOutlineTrash className="text-light-gray" />
              <p className="text-light-gray text-center text-sm">
                Vaciar todo el carrito
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShoppingCart;
