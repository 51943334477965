export default function answerByCode(code) {
     switch (code) {
         case 100:
             return 'La información que ingresaste es incorrecta. Por favor vuelve a intentarlo.';
             break;
         case 101:
             return  'El email/documento que ingresaste ya está siendo utilizado.';
             break;
         case 1:
             return 'Por favor revisa tu email y confirma tu cuenta.';
             break;
         case 111:
             return 'No pudimos obtener el email desde tu perfil. Por favor ingresa tu email para continuar.';
             break;
         case 401:
             return 'Verifica tu email y/o contraseña.';
             break;
         case 403:
             return 'No tiene permiso para realizar esta operación';
             break;
         case 102:
             return "No hemos encontrado tu cuenta. Por favor cree una cuenta para comenzar a operar";
             break;
         case 103:
             return "No se ha podido confirmar el registro. Usuario activo o bloqueado";
             break;
         case 104:
             return "El documento que intenta subir es demasiado grande";
             break;
         case 105:
             return "Ha ocurrido un error al adjuntar el archivo. Intente más tarde";
             break;
         case 107:
             return "Su usuario no está habilitado para operar. Contacte a soporte.";
             break;
         case 108:
             return "Monto excedido. Intente con un monto menor.";
             break;
         case 109:
             return "No se pudo realizar la operación. Contraseña incorrecta.";
             break;
         case 113:
             return "Fondos insuficientes para completar esta operación.";
             break;
         case 114:
             return "No se pudo realizar la operación. No existe un cliente con el email ingresado.";
             break;
         case 116:
             return "Le enviamos un email para que confirme su cuenta.";
             break;
         case 120:
             return "Su código es invalido.";
             break;
         case 121:
             return "Su código es inválido o ha expirado.";
             break;
         case 122:
             return "Ya existe un usuario con el DNI y Email ingresado.";
             break;
         case 123:
             return "Ya existe un usuario con el Email ingresado.";
             break;
         case 124:
             return "Ya existe un usuario con el Documento ingresado.";
             break;
         case 125:
             return "No hemos encontrado su cuenta. Por favor regístrese para poder operar.";
             break;
         case 126:
             return "El código ingresado es inválido";
             break;
         case 127:
             return "Su usuario se encuentra bloqueado, por favor contacte con nuesto equipo";
             break;
         case 128:
             return "Por favor confirme su email o recupere su contraseña para comenzar a operar";
             break;
         case 129:
             return "No encontramos la dirección ingresada";
             break;
         case 130:
             return "Esta versión de PagoLínea está desactualizada. Por favor, descarga la última versión para seguir disfrutando de nuestros servicios.";
             break;
         case 131:
             return "Por favor verifique su contraseña y vuelva a intentarlo.";
             break;
         case 132:
             return "La dirección de envío y destino no pertenecen a la misma moneda.";
             break;
         case 133:
             return "Su cuenta se encuentra pendiente de validación. Le notificaremos tan pronto lo hagamos";
             break;
         case 134:
             return "Su cuenta necesita nuestra validación."
             break;
         case 1000:
             return "No tiene suficientes fondos para realizar esta operación.";
             break;
         case 1001:
             return "El comprobante es obligatorio.";
             break;
         case 1002:
             return "Debe seleccionar las monedas para poder continuar.";
             break;
         case 1003:
             return "Su sesión ha expirado. Por favor, vuelva a iniciar sesión";
             break;
         case 1004:
             return "Debe seleccionar un remitente y un receptor";
             break;
         case 1005:
             return "Debe completar los datos de su cuenta para poder operar con nosotros";
             break;
         case 500:
             return "Disculpe, estamos en mantenimiento. Por favor, intente de nuevo más tarde.";
             break;
         default:
             return "Ha ocurrido un error inesperado.";
             break;
     }
 }