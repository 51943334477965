import React from "react";
import { IoMdCloseCircleOutline, IoMdClose } from "react-icons/io";
import { FaOpencart } from "react-icons/fa";

function CheckoutAlert({ isAlertOpened, setIsAlertOpened }) {
  const handleAlert = () => {
    setIsAlertOpened({ state: false });
  };

  return (
    <div
      className={`bg-white shadow-2xl border-l-[10px] border-main-purple fixed top-[10%] flex justify-between items-center py-[20px] px-[10px] rounded-r-xl space-x-[15px] w-[70%] z-[1100] ${
        isAlertOpened.style === "checkout" ? "lg:w-[35%]" : "lg:w-[20%]"
      } ease-in-out ${
        isAlertOpened.state === true
          ? "left-0 duration-500"
          : "left-[-100%] duration-500"
      }`}
    >
      {isAlertOpened.style === "checkout" ? (
        <>
          <div>
            <IoMdCloseCircleOutline className="text-main-purple text-4xl" />
          </div>
          <div>
            <p className="text-black lg:text-xl font-medium">Alerta!</p>
            <span className="text-light-gray text-sm lg:text-base">
              {isAlertOpened.message}
            </span>
          </div>
          <div className="cursor-pointer" onClick={() => handleAlert()}>
            <IoMdClose className="text-light-gray" />
          </div>
        </>
      ) : (
        <>
          <div>
            <FaOpencart className="text-main-purple text-4xl" />
          </div>
          <div>
            <p className="text-black lg:text-xl font-medium">
              Agregado Exitosamente
            </p>
          </div>
          <div className="cursor-pointer" onClick={() => handleAlert()}>
            <IoMdClose className="text-light-gray" />
          </div>
        </>
      )}
    </div>
  );
}

export default CheckoutAlert;
