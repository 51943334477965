import imageJonathan from "../assets/images/image-jonathan.webp";
import imageKira from "../assets/images/image-kira.webp";
import imagePatrick from "../assets/images/image-patrick.webp";

export const TestimoniosInfo = [
  {
    photo: imageJonathan,
    name: "Matt Uribb",
    amountStars: 5,
    description: "Está re copado!",
  },
  {
    photo: imageKira,
    name: "Herik",
    amountStars: 3,
    description: "Delivery se demoró un poco",
  },
  {
    photo: imagePatrick,
    name: "Diega Caicedo",
    amountStars: 5,
    description:
      "Me encanta esta app, el servicio es rápido, las personas encargadas de los domicilios son super amables. Me gusta que puedo pagar con crypto",
  },
];
