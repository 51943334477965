import React from "react";
import { HiOutlineTrash } from "react-icons/hi";

function UserAddress({ address, onClick }) {
  return (
    <ul className="flex justify-between text-black text-base">
      <li className="flex-[2] ">{address.name}</li>
      <li className="flex-[6] ">{address.lineOne}</li>
      <li className="flex-[2] flex items-center justify-between">
        {address.lineTwo}
        <HiOutlineTrash
          className="text-main-purple cursor-pointer"
          onClick={onClick}
        />{" "}
      </li>
    </ul>
  );
}

export default UserAddress;
