import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Restaurant({ store }) {
  const navigate = useNavigate();
  const animate = {
    hover: {
      scale: 1.05,
      boxShadow: "rgba(99, 11, 134, 0.05) 0px 8px 24px",
    },
  };
  return (
    <motion.div
      className="flex flex-col items-center space-y-[15px] cursor-pointer"
      whileHover="hover"
      onClick={() => navigate(`/perfil/${store.link}`)}
    >
      <motion.img
        src={store.banner.url}
        alt={store.name}
        className="w-[150px] h-[150px] rounded-full aspect-square object-cover"
        variants={animate}
      />
      <p className="text-black">{store.name}</p>
    </motion.div>
  );
}

export default Restaurant;
