import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../routes/Home";
import Aliados from "../routes/Aliados";
import Nosotros from "../routes/Nosotros";
import Tiendas from "../routes/Tiendas";
import Perfil from "../routes/Perfil";
import Pedido from "../routes/Pedido";
import Usuario from "../routes/Usuario";
import { AnimatePresence } from "framer-motion";
import CartProvider from "../context/CartProvider";
import NotificationPage from "./notificaciones/NotificacionPage";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <CartProvider>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/tiendas" element={<Tiendas />} />
          <Route path="/aliados" element={<Aliados />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/perfil/:link" element={<Perfil />} />
          <Route path="/pedido" element={<Pedido />} />
          <Route path="/usuario" element={<Usuario />} />
          <Route path="/notificacion_pago" component={NotificationPage} />
          <Route path="/aprobado" component={NotificationPage} />
          <Route path="/pendiente" component={NotificationPage} />
          <Route path="/rechazado" component={NotificationPage} />
        </Routes>
      </AnimatePresence>
    </CartProvider>
  );
}

export default AnimatedRoutes;
