export default function shippingOption(option) {
  switch (option) {
    case "Entrega":
      return "DELIVERY";
      break;
    case "Retiro en local":
      return "PICKUP";
      break;
    case "Servicio a la mesa":
      return "SERVICE";
      break;
    default:
      return "NONE";
      break;
  }
}
