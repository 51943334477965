import React, { useState } from "react";
import loginImg from "../assets/images/loginImg.svg";
import { Formik } from "formik";
import * as yup from "yup";
import answerByCode from "../utils/answerByCode";
import { useCartContext } from "../context/CartProvider";
import { useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { motion } from "framer-motion";

function SignInModal({ isSignInActive, setIsSignInActive }) {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [loginError, setLoginError] = useState("");
  const [signInOpen, setSignInOpen] = useState(false);
  const { userToken, setUserToken } = useCartContext();

  const handleSignIn = () => {
    setIsSignInActive(!isSignInActive);
  };
  const handleSignInOption = () => {
    setSignInOpen(!signInOpen);
  };

  const loginValidations = yup.object({
    email: yup.string().email("Ingresa un email valido").required("Requerido"),
    password: yup.string().required("Requerido"),
  });

  const loginActions = (values) => {
    try {
      fetch(`https://api.pagolinea.com/v2/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
          version: 3,
        }),
      })
        .then((res) => res.json())
        .then((resUser) => {
          if (resUser.code) {
            setLoginError(answerByCode(resUser.code));
          } else {
            setUserToken(localStorage.setItem("user-tk", resUser.token));
            window.location.reload();
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={`fixed w-full h-full bg-[#F3F4F6] right-0  z-[1400] flex-1 flex flex-col md:flex-row overflow-y-scroll ${
        isSignInActive ? "top-0 duration-500" : "top-[-1000%] duration-500"
      }`}
    >
      <div className="flex-[0.5] w-full h-full flex justify-center items-center md:order-2 relative">
        <img src={loginImg} alt="Login PagoTienda" />
        <IoMdClose
          className="text-3xl absolute top-[10%] right-[10%] cursor-pointer text-black"
          onClick={handleSignIn}
        />
      </div>
      <div className="flex-[0.5] w-full h-full flex md:order-1 justify-center items-center">
        <div className="bg-white shadow-sm w-full h-full md:w-[70%] md:h-[80%] flex flex-col items-center p-[16px] md:p-[28px] rounded-[20px] space-y-[30px] overflow-hidden">
          {/* <SignInOption /> */}
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={loginValidations}
            onSubmit={(values, actions) => {
              loginActions(values);
            }}
          >
            {(formikprops) => (
              <div className="w-full h-full flex flex-col justify-between space-y-[30px] lg:space-y-0">
                <div className="w-full flex flex-col justify-center items-center">
                  <h3 className="text-black text-4xl">Inicia Sesión</h3>
                  <p className="text-black">
                    Empezá a comprar desde donde quiera que estes
                  </p>
                </div>
                <div className="flex flex-col space-y-[15px]">
                  <label className="text-sm text-black">
                    Email
                    <input
                      type="email"
                      className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                      placeholder="Ingresa tu email"
                      value={email}
                      onChange={(e) => {
                        const value = e.target.value;
                        setEmail(value);
                        formikprops.values.email = value;
                      }}
                    />
                    {formikprops.touched.email && formikprops.errors.email && (
                      <span className="text-[12px] text-alert">
                        {formikprops.errors.email}
                      </span>
                    )}
                  </label>
                  <label className="text-sm text-black relative">
                    Password
                    <input
                      type={!isPasswordVisible && "password"}
                      className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                      placeholder="Ingresa tu contraseña"
                      value={password}
                      onChange={(e) => {
                        const value = e.target.value;
                        setPassword(value);
                        formikprops.values.password = value;
                      }}
                    />
                    {formikprops.touched.password &&
                      formikprops.errors.password && (
                        <span className="text-[12px] text-alert">
                          {formikprops.errors.password}
                        </span>
                      )}
                  </label>
                </div>
                <div className="flex flex-col items-center space-y-[15px]">
                  <div className="w-full">
                    <motion.button
                      onClick={() => {
                        formikprops.handleSubmit();
                      }}
                      className="bg-main-purple w-full py-[10px] rounded-tl-xl rounded-br-xl text-white"
                      whileHover={{ backgroundColor: "#44045E" }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Ingresar
                    </motion.button>
                    {loginError !== "" && (
                      <span className="text-sm text-alert">{loginError}</span>
                    )}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <a
                      href="https://app.pagolinea.com/pages/register"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-sm text-black">
                        ¿No tenes cuenta?{" "}
                        <span
                          className="text-main-purple"
                          onClick={handleSignInOption}
                        >
                          Registrate ahora
                        </span>
                      </span>
                    </a>
                    <a
                      href="https://app.pagolinea.com/pages/forgot-password"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-sm text-black">
                        ¿Olvidates tu contraseña?
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default SignInModal;
