import React from "react";

function AddressRadio({ address, onClick, defaultChecked = false }) {
  return (
    <label className="containerLabel" id={address.id} onClick={onClick}>
      {address.name + " - " + address.lineOne}
      <input type="radio" name="addressRadio" defaultChecked={defaultChecked} />
      <span className="checkmarkLabel"></span>
    </label>
  );
}

export default AddressRadio;
