import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { HiOutlineTrash, HiOutlinePlus, HiMinus } from "react-icons/hi";
import { motion } from "framer-motion";
import noImg from "../assets/images/noImage.svg";
import { useCartContext } from "../context/CartProvider";
import Alert from "./alerts/Alert";

function Overlay({ setSelected, selected, currency }) {
  const [quantity, setQuantity] = useState(1);
  const [other, setOther] = useState(""); //Comments we should know
  const [activeAdditions, setActiveAdditions] = useState([]);
  const [stockError, setStockError] = useState("");
  const {
    setItemsInCart,
    itemsInCart,
    isAlertOpened,
    setIsAlertOpened,
    isAlertAdded,
    setIsAlertAdded,
  } = useCartContext();

  const handleAlert = () => {
    setIsAlertOpened(!isAlertOpened);
    closeAll();
  };

  const setAdditions = (addition, limit, id) => {
    const activeAdditionsId = activeAdditions.map(
      (el) => el.additionCategory.id
    );
    const clickedAdditionId = addition.additionCategory.id;

    if (limit <= 1) {
      if (activeAdditionsId.includes(clickedAdditionId)) {
        setActiveAdditions([
          ...activeAdditions.filter(
            (el) => el.additionCategory.id !== clickedAdditionId
          ),
          addition,
        ]); //*** Create a new array without the previous one that has the same id of the new one and add the new one so i just have one with that ID
      } else {
        setActiveAdditions([...activeAdditions, addition]); //*** If it does not exist just add it
      }
    } else {
      //If limit is greater than 1

      const inputAddition = document.getElementById(`${id}`);
      if (inputAddition.checked !== false) {
        setActiveAdditions([...activeAdditions, addition]);
      } else {
        setActiveAdditions([
          ...activeAdditions.filter((el) => el.id !== addition.id),
        ]);
      }
    }
  };

  const setComments = (e) => {
    setOther(e.target.value);
  };

  useEffect(() => {
    if (stockError !== "") {
      const timeoutId = setTimeout(() => {
        setStockError("");
      }, 8000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [stockError]);

  useEffect(() => {
    if (selected) {
      if (quantity < selected.stockLevel) {
        setStockError("");
      }
    }
  }, [selected, quantity]);

  function limitCheckBoxes(limitCheckBoxes = 3, selected) {
    if (selected && selected.additions !== []) {
      let checkboxGroup = document
        .getElementById("formBoxes")
        .getElementsByTagName("input"); //input

      if (checkboxGroup.length) {
        let limit = checkboxGroup[0].type === "radio" ? 4 : limitCheckBoxes; //* If it is a radio let it be a high number, it does not matter because radio input will just let choose one, else limitCheckboxes*/

        for (let index = 0; index < checkboxGroup.length; index++) {
          checkboxGroup[index].onclick = function () {
            let checkedCount = 0;
            for (let index = 0; index < checkboxGroup.length; index++) {
              checkedCount += checkboxGroup[index].checked ? 1 : 0;
            }
            if (checkedCount > limit) {
              this.checked = false;
            }
          };
        }
      }
    }
  }

  useEffect(() => {
    const limit = !selected
      ? []
      : selected.additions.map((item) => item.additionCategory.limit);

    limitCheckBoxes(limit[0], selected);
  }, [selected]);

  const closeAll = () => {
    setTimeout(function () {
      setIsAlertOpened(false);
    }, 4000);
  };

  // Dont allow scrolling when visible
  const bodyStyle = document.body.style;
  if (selected > 0 || isAlertOpened) {
    bodyStyle.overflowY = "hidden";
  } else {
    bodyStyle.overflowY = "auto";
  }

  const addProductToCart = () => {
    const sentToCartProduct = JSON.parse(JSON.stringify(selected));
    const activeAdditionsSend = activeAdditions.map((item) => [item]);
    const totalAdditions = activeAdditions.map((item) => item.price);

    sentToCartProduct.totalAdditions = parseInt(
      totalAdditions.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      )
    );
    sentToCartProduct.activeAdditions = activeAdditionsSend;
    sentToCartProduct.qty = quantity;
    sentToCartProduct.totalPrice =
      sentToCartProduct.price + sentToCartProduct.totalAdditions;
    if (other !== "") {
      sentToCartProduct.other = other;
    }

    if (itemsInCart.length > 0) {
      if (itemsInCart[0].storeId !== sentToCartProduct.storeId) {
        handleAlert();
        close();
      } else {
        const itemsId = itemsInCart.map((item) => item.id);
        if (itemsId.includes(sentToCartProduct.id) === true) {
          const itemsActiveAdditions = itemsInCart.map((item) =>
            item.activeAdditions.map((item) => item[0].id)
          );

          const itemSentToCartActiveAdditions =
            sentToCartProduct.activeAdditions.map((item) => item[0].id);

          // **** Validate if the product i wanna push is the same or has different additions ****
          if (
            JSON.stringify(itemsActiveAdditions).includes(
              JSON.stringify(itemSentToCartActiveAdditions)
            )
          ) {
            const item = itemsInCart.find((item) => {
              const itemsActiveAdditionsId = item.activeAdditions.map(
                (item) => item[0].id
              );
              return (
                JSON.stringify(itemsActiveAdditionsId) ===
                JSON.stringify(itemSentToCartActiveAdditions)
              );
            });
            item.qty += 1;
          } else {
            setItemsInCart([...itemsInCart, sentToCartProduct]);
          }
          close();
          setIsAlertAdded({ state: true, message: "Agregado al carrito!" });
          //notification
        } else {
          setItemsInCart([...itemsInCart, sentToCartProduct]);
          close();
          setIsAlertAdded({ state: true, message: "Agregado al carrito!" });
          //notification
        }
      }
    } else {
      setItemsInCart([...itemsInCart, sentToCartProduct]);
      close();
    }
  };

  const divAnimation = {
    offScreen: { opacity: 0 },
    onScreen: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  // ********* Increase quantity of product *******
  function increase() {
    if (quantity < selected.stockLevel) {
      setQuantity(quantity + 1);
    }
    if (quantity >= selected.stockLevel) {
      setStockError("No hay suficiente stock, solo podras llevar " + quantity);
    }
  }
  function decrease() {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  }

  if (!selected) {
    return <></>;
  }

  if (quantity < 1) {
    close();
  }

  function close() {
    setSelected(!selected);
    setQuantity(1);
    setActiveAdditions([]);
    setOther("");
  }

  return (
    <div
      className="fixed inset-0 bg-black/50 z-[1010] flex justify-center items-center h-auto overflow-y-scroll"
      onClick={() => close()}
    >
      <motion.div
        className="bg-white rounded-lg w-[90%] lg:w-[60%] lg:h-[70%] flex flex-col p-[16px] relative min-w-[320px] overflow-y-scroll"
        onClick={(e) => e.stopPropagation()}
        initial={"offScreen"}
        animate={"onScreen"}
        variants={divAnimation}
      >
        {/* main */}
        <div className="h-[90%] w-full flex flex-col lg:flex-row lg:space-x-[30px]">
          {/* div img */}
          <div className="w-full h-[70%] lg:w-[50%] lg:h-full pb-[20px]">
            <img
              src={`${selected.image ? selected.image.url : noImg}`}
              className="w-full h-full"
              alt={"PagoTienda"}
            />
          </div>
          {/* div text */}
          <div className="w-full h-[30%] lg:w-[50%] lg:h-full space-y-[25px]">
            <div className="flexflex-col justify-start items-center lg:items-start h-[45%] ">
              <h3 className="text-black text-2xl lg:text-4xl">
                {selected.name.toUpperCase()}
              </h3>
              <span className="text-black">
                {currency}
                {selected.price * quantity}{" "}
                {quantity >= 2 && (
                  <span className="text-light-gray text-[10px]">
                    ({currency}
                    {selected.price}c/u)
                  </span>
                )}
              </span>
              <div className="">
                <p className="text-gray text-base">{selected.description}.</p>
              </div>
            </div>
            <div className="h-[55%] overflow-y-scroll border-t-[1px] border-light-gray pt-[16px] space-y-[10px]">
              <div id="formBoxes" className="grid md:grid-cols-2 gap-[15px]">
                {selected.additions.map((addition) => (
                  <div
                    className="flex items-center space-x-[8px] text-light-gray"
                    key={addition.id}
                  >
                    <span className="text-[8px]">
                      {addition.additionCategory.name}
                    </span>

                    <input
                      type={`${
                        addition.additionCategory.limit >= 2
                          ? "checkbox"
                          : "radio"
                      }`}
                      name={addition.additionCategory.name}
                      id={addition.id}
                      onClick={() => {
                        setAdditions(
                          addition,
                          addition.additionCategory.limit,
                          addition.id
                        );
                      }}
                    />

                    <span className="text-black text-sm">
                      {addition.addition.name}{" "}
                      <span className="text-light-gray text-[10px]">
                        ({currency} ${addition.price})
                      </span>
                    </span>
                  </div>
                ))}
              </div>
              <div className="w-full">
                <textarea
                  className="border-[1px] border-light-gray rounded-xl w-full p-[10px] focus-visible:outline-main-purple placeholder-light-gray"
                  placeholder="¿Hay algo que debamos saber?"
                  onChange={setComments}
                />
              </div>
            </div>
          </div>
        </div>

        {/* secondary */}
        {stockError !== "" && (
          <span className="text-main-purple text-sm">{stockError}</span>
        )}
        <div className="h-[10%] w-full flex justify-center items-center flex-col lg:flex-row space-y-[15px] lg:w-[50%] lg:pr-[15px] lg:space-y-0 lg:justify-between select-none">
          <div className="flex w-[115px] justify-between items-center p-[10px] rounded-lg border-[1px] border-light-gray">
            {quantity === 1 ? (
              <HiOutlineTrash
                className="text-light-gray cursor-pointer"
                onClick={decrease}
              />
            ) : (
              <HiMinus
                className="text-light-gray cursor-pointer"
                onClick={decrease}
              />
            )}
            <span className="text-black">{quantity}</span>
            <HiOutlinePlus
              className="text-light-gray cursor-pointer"
              onClick={increase}
            />
          </div>
          <motion.button
            className="w-full bg-main-purple p-[10px] text-white font-medium rounded-lg lg:w-[50%]"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95, backgroundColor: "#44045E" }}
            onClick={addProductToCart}
          >
            Agregar al Carrito
          </motion.button>
        </div>
        <div
          className="absolute bg-white rounded-full right-[16px] top-[50px] p-[10px] lg:top-[16px] cursor-pointer"
          onClick={close}
        >
          <IoMdClose className="text-[20px] text-light-gray" />
        </div>
      </motion.div>
      <Alert
        isAlertOpened={isAlertOpened}
        setIsAlertOpened={setIsAlertOpened}
      />
    </div>
  );
}

export default Overlay;
