import React, { useState } from "react";
import { motion } from "framer-motion";
import Overlay from "./Overlay";
import { useNavigate } from "react-router-dom";
import AddToCartButton from "./AddToCartButton";
import { HiOutlineArrowNarrowUp } from "react-icons/hi";

function Shop({ store, currency }) {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const StoreProducts = () => {
    return store.products.map((product) => (
      <div
        className="flex flex-col lg:flex-row border-b-[1px] border-[#757575] pb-[10px] space-y-[20px] lg:space-y-0  items-center"
        key={product.id}
      >
        <div className="w-[100%] lg:w-[70%]">
          <h4 className="text-black text-2xl uppercase">{product.name}</h4>
          <p className="text-[#757575] text-sm">{product.description}</p>
        </div>
        <div className="w-[100%] lg:w-[30%] flex flec-col items-center justify-start lg:justify-end space-x-[15px] ">
          <p className="text-black text-xl">
            {store.currency.code}
            {product.price}
          </p>
          <AddToCartButton
            onClick={() => {
              if (product.stockLevel !== 0) {
                setSelected(product);
              }
            }}
            product={product}
          />
        </div>
      </div>
    ));
  };

  return (
    <div className="">
      <abbr title="Visitar Página" className="decoration-transparent">
        <motion.div
          className="flex flex-col lg:flex-row lg:space-y-p lg:space-x-[20px] lg:h-[400px] mb-[50px] cursor-pointer rounded-[20px] relative border-[1px] border-[#f7f7f7]"
          onClick={() => navigate(`/perfil/${store.link}`)}
          whileHover={{
            scale: 1.01,
            boxShadow: "rgba(99, 11, 134, 0.05) 0px 8px 24px",
          }}
          transition={{ duration: 0.2 }}
        >
          <div className="w-full lg:w-[50%] ">
            <img
              src={store.banner.url}
              alt={store.banner.name}
              className="lg:h-[400px] w-full rounded-[20px]"
            />
          </div>
          <div className="space-y-[20px] flex flex-col justify-center w-full lg:w-[50%]">
            <h3 className="text-4xl font-medium text-black text-center">
              {store.name}
            </h3>
            <p className="text-gray">
              📍{store.address.line_one}, {store.address.line_two}.
            </p>
          </div>
          <div className="absolute w-[25px] h-[25px] top-[10px] right-[10px] mt-0 pt-0 rotate-45  flex items-center justify-center">
            <HiOutlineArrowNarrowUp className="text-main-purple text-2xl" />
          </div>
        </motion.div>
      </abbr>
      <div>
        <StoreProducts />
      </div>
      <Overlay
        setSelected={setSelected}
        selected={selected}
        currency={currency}
      />
    </div>
  );
}

export default Shop;
