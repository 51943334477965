import React, { useEffect, useState, useRef } from "react";
import QRCode from "qrcode.react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { HiOutlineArrowNarrowDown } from "react-icons/hi";
import { HiChevronLeft } from "react-icons/hi";
import noImg from "../assets/images/noImage.svg";
import { useCartContext } from "../context/CartProvider";
import FormikInput from "../components/FormikInput";
import { Formik, isEmptyArray } from "formik";
import * as yup from "yup";
import AddressRadio from "../components/AddressRadio";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { MdLocationPin } from "react-icons/md";
import shippingOption from "../utils/shippingOption";
import paymentMethod from "../utils/paymentMethod";
import { IoMdClose } from "react-icons/io";
import SuccessPopUp from "../components/SuccessPopUp";
import answerByCode from "../utils/answerByCode";
import CheckoutAlert from "../components/alerts/CheckoutAlert";
import { goToAnotherPage } from "./goToAnotherPage";
import UseWindowDimensions from "../utils/UseWindowDimensions";
import { BsWallet2, BsInfoCircle } from "react-icons/bs";
import cryptoImg from "../utils/cryptoImg";
import Checkout from "../components/Checkout";

function Pedido() {
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentStore, setCurrentStore] = useState([]);
  const [loggedInDeliveryAddress, setLoggedInDeliveryAddress] = useState([0]);
  const [storeDiscount, setStoreDiscount] = useState(0);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [selectedShippingOption, setSelectedShippingOption] =
    useState("Retiro en local");
  const [selectedPaymentOption, setSelectedPaymentOption] =
    useState("Efectivo");
  const [selectedNeighbourhood, setSelectedNeighbourhood] = useState(null);
  const [neighbourhoods, setNeighbourhoods] = useState([]);

  const [addresses, setAddresses] = useState([]);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [details, setDetails] = useState(""); //Not required
  const [postalCode, setPostalCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [resultsMapsApi, setResultsMapsApi] = useState([]);
  const {
    itemsInCart,
    setPrice,
    price,
    userToken,
    setUserToken,
    userInfo,
    setOrderNumber,
    stores,
  } = useCartContext();

  const [total, setTotal] = useState(price);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [isIframeRegisterVisible, setisIframeRegisterVisible] = useState(false);
  const [isIframePasswordVisible, setisIframePasswordVisible] = useState(false);
  const [isSuccessPopUpActive, setIsSuccessPopUpActive] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [orderError, setOrderError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [isAlertOpened, setIsAlertOpened] = useState({
    state: false,
    message: "",
    style: "checkout",
  });
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState({
    state: false,
    data: "",
  });
  const [walletDigits, setWalletDigits] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);

  const [showBtMp, setShowBtMp] = useState(false);
  const [statusMP, setStatusMP] = useState(false);
  const [itemMp, setItemMp] = useState([]);
  const [preferenceId, setPreferenceId] = useState("");

  const myRef = useRef(null);

  const infoMotion = {
    rest: { display: "none", duration: 0.2 },
    hover: {
      display: "flex",
      transition: {
        duration: 0.2,
      },
    },
  };
  const inputValidations = selectedShippingOption === "Entrega" && userToken === null
      ? yup.object({
          name: yup.string().required("Requerido"),
          email: yup
            .string()
            .required("Requerido")
            .email("Ingresa un email valido"),
          postalCode: yup.string().required("Requerido"),
          phoneNumber: yup.number().required("Requerido"),
        })
      : selectedShippingOption === "Retiro en local"
      ? yup.object({
          name: yup.string().required("Requerido"),
          email: yup
            .string()
            .required("Requerido")
            .email("Ingresa un email valido"),
          phoneNumber: yup.number().required("Requerido"),
        })
      : selectedShippingOption === "Servicio a la mesa"
      ? yup.object({
          name: yup.string().required("Requerido"),
          tableNumber: yup.string().required("Requerido"),
        })
      : selectedPaymentOption === "Pago con PagoLinea" && userToken === null
      ? yup.object({
          emailLogin: yup
            .string()
            .required("Requerido")
            .email("Ingresa email valido"),
          passwordLogin: yup.string().required("Requerido"),
        })
      : null;

  const handlePrice = () => {
    let answer = 0;
    itemsInCart.map((item) => (answer += item.totalPrice * item.qty));
    setPrice(answer);
    if (currentStore !== []) {
      if (selectedPaymentOption === "Pago con PagoLinea") {
        if (currentStore[0].discount !== null) {
          setStoreDiscount(currentStore[0].discount.value);
        }
      } else {
        setStoreDiscount(0);
      }
    }
    let subTotal = price - price * storeDiscount;
    if (selectedShippingOption === "Entrega") {
      let fixed = subTotal + deliveryFee;
      if (deliveryFee > 0) {
        setTotal(fixed.toFixed(2));
      } else {
        setTotal(fixed);
      }
    } else {
      setTotal(subTotal);
    }
  };

  // Función que se ejecuta al cargar la página de retorno de Mercado Pago
  const handlePaymentApproval = () => {
    // Obtener los parámetros de la URL
    const searchParams = new URLSearchParams(window.location.search);
    const paymentId = searchParams.get('payment_id');
    const status = searchParams.get('status');
    // Realizar acciones en base a la respuesta de Mercado Pago
    console.log(status)
    if (status === 'approved') {
      setStatusMP(true)
      return (
        <div className="flex justify-center items-center w-[100vw] h-[100vh] flex-col space-y-[20px]">
          <p>¡Su pago fue Procesado!</p>
          <button
            className="bg-main-purple rounded-tl-xl rounded-br-xl text-white py-[10px] px-[50px]"
            onClick={makeOrder()}
          >
            ¡Hacer Pedido!
          </button>
        </div>
      );
    } else {
      setStatusMP(false)
    }
  };

  useEffect(() => {
    handlePrice();
    const items = itemsInCart.map((item) => {
        return {
            id: item.id,
            title: item.name,
            description: item.description,
            quantity: item.qty,
            currency_id: 'ARS',
            unit_price: item.price,
        };
    });
    setItemMp(items)

    handlePaymentApproval();
  }, []);

  useEffect(()=>{
    const formData = {
      purpose: "wallet_purchase",
      items: itemMp,
      back_urls: {
          "success": "https://pagotienda.com/pedido",
          "failure": "https://pagotienda.com/pedido",
          "pending": "https://pagotienda.com/pedido"
      },
      auto_return: "approved",
      payment_methods: {
          "excluded_payment_methods": [
              {
                  "id": "master"
              }
          ],
          "excluded_payment_types": [
              {
                  "id": "ticket"
              }
          ],
          "installments": 12
      },
      notification_url: "https://pagotienda.com/pedido"
    };
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json', Accept: '*/*'},
      body: JSON.stringify(formData) 
    };
    
    fetch('https://services.pagotienda.com/mp/pagar', options)
    .then((res) => res.json())
    .then((resJSON) => {
      setPreferenceId(resJSON.preferenceId);
    })
    .catch(err => console.error(err));

  }, [itemMp])

  useEffect(() => {
    if (isLoaded === true) {
      fetch(`https://services.pagotienda.com/settings/currencies`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((resJSON) => {
          setCurrencies(resJSON.currencies, "currency");
        });

      fetch(`https://services.pagotienda.com/settings/cities`, {
        method: "GET",
        accept: "application/json",
      })
        .then((res) => res.json())
        .then((resJSON) => {
          setNeighbourhoods(resJSON.cities);
        });

      fetch(`https://services.pagotienda.com/settings/countries`)
        .then((res) => res.json())
        .then((resJson) => {
          setCountriesOptions(resJson);
        });
    }
  }, [isLoaded]);

  useEffect(() => {
    const currentStoreRes =
      stores.length && itemsInCart.length
        ? stores.filter((el) => el.id === itemsInCart[0].storeId)
        : null;
    setCurrentStore(currentStoreRes);

    if (stores.length && itemsInCart.length) {
      setIsLoaded(true);
    }

  }, [stores, itemsInCart]);

  /* SET CRYPTO CURRENCIES */
  useEffect(() => {
    if (
      isEmptyArray(currentStore) === false &&
      currentStore !== null &&
      currentStore !== undefined &&
      selectedPaymentOption === "Pago con Crypto moneda"
    ) {
      fetch(
        `https://services.pagotienda.com/store/profile/${currentStore[0].link}`
      )
        .then((res) => res.json())
        .then((resJson) => {
          setCryptoCurrencies(resJson.currencies);
        });
    }
  }, [currentStore, isLoaded, selectedPaymentOption]);

  useEffect(() => {
    if (
      isEmptyArray(cryptoCurrencies) === false &&
      cryptoCurrencies !== null &&
      cryptoCurrencies !== undefined &&
      isLoaded === true &&
      selectedPaymentOption === "Pago con Crypto moneda"
    ) {
      setSelectedCrypto({
        state: true,
        data: cryptoCurrencies[0],
      });
    }
  }, [cryptoCurrencies]);

  useEffect(() => {
    if (orderError !== "") {
      const timeoutId = setTimeout(() => {
        setOrderError("");
      }, 8000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [orderError]);

  useEffect(() => {
    if (selectedCrypto.state === true) {
      fetch(
        `https://api.pagolinea.com/v2/pt/rate?from=${currentStore[0].currency.id}&to=${selectedCrypto.data.currency.id}&amount=${total}`
      )
        .then((res) => res.json())
        .then((resJSON) => {
          setExchangeRate(resJSON.rate);
        });
    }
  }, [selectedCrypto]);

  useEffect(() => {
    if (userToken !== null && isLoaded === true) {
      fetch(`https://api.pagolinea.com/v2/address`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((res) => res.json())
        .then((resJSON) => {
          if(resJSON.code === 200){
            setAddresses(resJSON.data);
          }else{
            console.log(resJSON)
          }
          console.log(resJSON)
        });

      setName(userInfo.firstName + " " + userInfo.lastName);
      setEmail(userInfo.email);
      setPhoneNumber(userInfo.phone);
    }
  }, [userToken, isLoaded]);

  useEffect(() => {
    if (
      isEmptyArray(addresses) === false &&
      userToken !== null &&
      isLoaded === true
    ) {
      setCoordinates({
        lat: addresses[0].latitude,
        lng: addresses[0].longitude,
      });
      setStreet(addresses[0].lineOne);
      setDetails(addresses[0].lineTwo);

      fetch(`https://services.pagotienda.com/checkout/shipping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sellerCountry: "259",
          to: {
            lat: addresses[0].latitude,
            lng: addresses[0].longitude,
          },
          from: {
            lat: currentStore[0].address.latitude,
            lng: currentStore[0].address.longitude,
          },
        }),
      })
        .then((res) => res.json())
        .then((resJSON) => {
          setDeliveryFee(resJSON);
        })
        .catch((err) => {
          console.error(err);
        });

      setName(userInfo.firstName + " " + userInfo.lastName);
      setEmail(userInfo.email);
      setPhoneNumber(userInfo.phone);
    }
  }, [addresses, userToken, isLoaded]);

  const getShippingValue = (id) => {
    const labelDoc = document.getElementById(`${id}`);
    setSelectedShippingOption(labelDoc.textContent);
  };

  const getPaymentValue = (id) => {
    const labelDoc = document.getElementById(`${id}`);
    console.log(labelDoc.textContent)
    setSelectedPaymentOption(labelDoc.textContent);
    setLoginError("");

    if(selectedPaymentOption === "Pago con Mercado pago" && preferenceId !== ""){
      setShowBtMp(true)
    }
    if (
      selectedPaymentOption === "Pago con PagoLinea" ||
      selectedPaymentOption === "Pago con Crypto moneda"||
      selectedPaymentOption === "Mercado Pago"
    ) {
      myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const getAddressValue = (id) => {
    const labelDoc = document.getElementById(`${id}`);
    setStreet(labelDoc.textContent);
    let deliveryAddress = addresses.filter(
      (address) =>
        address.name + " - " + address.lineOne === labelDoc.textContent
    );
    setLoggedInDeliveryAddress(deliveryAddress[0]);
    setCoordinates({
      lat: loggedInDeliveryAddress.latitude,
      lng: loggedInDeliveryAddress.longitude,
    });
    fetch(`https://services.pagotienda.com/checkout/shipping`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sellerCountry: "259",
        to: {
          lat: coordinates.lat,
          lng: coordinates.lng,
        },
        from: {
          lat: currentStore[0].address.latitude,
          lng: currentStore[0].address.longitude,
        },
      }),
    })
      .then((res) => res.json())
      .then((resJSON) => {
        setDeliveryFee(resJSON);
      })
      .catch((err) => {
        console.error(err);
      });
  };
console.log(currentStore)
  const ItemInCartCard = ({ product }) => {

    
    return (
      <div className="flex justify-between flex-row">
        <div className="flex flex-row space-x-[30px] flex-1">
          <div className="w-[100px] h-[100px] relative">
            <img
              src={product.image ? product.image.url : noImg}
              alt={"Pago tienda"}
              className="w-full h-full rounded-xl min-w-[100px]"
            />
          </div>
          <div className="flex flex-col">
            <p className="text-base text-black">{product.name}</p>
            <span className="text-[10px] text-black">
              {product.activeAdditions !== [] &&
                product.activeAdditions.map((item) => item[0].addition.name) +
                  " "}{" "}
            </span>
            <span className="text-base text-light-gray">
              {currentStore[0].currency.code}
              {product.totalPrice}
            </span>
          </div>
        </div>

        <div className="flex items-center text-main-purple">
          x {product.qty}
        </div>
      </div>
    );
  };

  if (!itemsInCart.length) {
    return (
      <div className="flex justify-center items-center w-[100vw] h-[100vh] flex-col space-y-[20px]">
        <p>¡No tienes nada por pagar!</p>
        <button
          className="bg-main-purple rounded-tl-xl rounded-br-xl text-white py-[10px] px-[50px]"
          onClick={() => goToAnotherPage(navigate, "/tiendas")}
        >
          ¡Ir a comprar!
        </button>
      </div>
    );
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setCoordinates(latLng);
    setStreet(value);
    setResultsMapsApi(results[0]);

    if (coordinates.lat !== null) {
      fetch(`https://services.pagotienda.com/checkout/shipping`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sellerCountry: "259",
          to: {
            lat: coordinates.lat,
            lng: coordinates.lng,
          },
          from: {
            lat: currentStore[0].address.latitude,
            lng: currentStore[0].address.longitude,
          },
        }),
      })
        .then((res) => res.json())
        .then((resJSON) => {
          setDeliveryFee(resJSON);
        });
    } else {
      setIsAlertOpened({
        state: true,
        message: "Al parecer no escogiste bien tu dirección",
      });
    }
  };

  const handleChangeNeighbourhood = () => {
    let select = document.getElementById("select");
    if (select !== null) {
      let value = document.getElementById("select").value;
      setSelectedNeighbourhood(value);
    }
  };

  const handleChangeCryptoCurrency = () => {
    let select = document.getElementById("selectCrypto");
    if (select !== null) {
      let name = document.getElementById("selectCrypto").value;
      let value = cryptoCurrencies.filter(
        (item) => item.currency.name === name
      );
      setSelectedCrypto({ state: true, data: value[0] });
    }
  };

  const handleChangeCurrency = () => {
    let select = document.getElementById("selectCurrency");
    if (select !== null) {
      let value = document.getElementById("selectCurrency").value;
      setSelectedCurrency(value);
    }
  };

  const fetchOrder = (order) => {
    console.log('dentro del fetch de orden ',order)
    fetch(`https://services.pagotienda.com/checkout/order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    })
      .then((res) => res.json())
      .then((resJSON) => {
        if (resJSON.statusCode !== 200) {
          setOrderError(resJSON.message);
        } else {
          setOrderNumber(resJSON.orderId);
          setIsSuccessPopUpActive(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const makeOrder = () => {
    console.log('entro a makeOrder')
    let order = {
      orderType: shippingOption(selectedShippingOption), //PICKUP -> Recogida en Local, SERVICE-> Servicio a la Mesa y DELIVERY -> Entrega
      deliveryArea: {
        deliveryCost: selectedShippingOption === "Entrega" ? deliveryFee : 0,
      },
      referenceMP_Id:'',
      lat:
        selectedShippingOption === "Entrega"
          ? { deliverylat: coordinates.lat }
          : null,
      lng:
        selectedShippingOption === "Entrega"
          ? { deliverylng: coordinates.lng }
          : null,
      country:
        selectedShippingOption === "Entrega"
          ? { name: "Argentina", id: 259 }
          : null,
      deliveryTime: "4:20",
      paymentMethod: paymentMethod(selectedPaymentOption),
      city: {
        id: 6,
        name: "",
        cashPrice: 0,
        plPrice: 0,
      },
      name: name,
      email: selectedShippingOption !== "Servicio a la mesa" ? email : null,
      street: selectedShippingOption === "Entrega" ? street : null,
      lineTwo: selectedShippingOption === "Entrega" ? details : null,
      zipCode: "0",
      phone:
        selectedShippingOption !== "Servicio a la mesa" ? phoneNumber : null,
      clientTable:
        selectedShippingOption === "Servicio a la mesa" && tableNumber,
      credentials: {
        email:
          selectedPaymentOption === "Pago con PagoLinea"
            ? "mateogooplay@gmail.com" //email
            : null,
        password:
          selectedPaymentOption === "Pago con PagoLinea"
            ? "2002Mateo" //password
            : null,
        currency:
          selectedPaymentOption === "Pago con PagoLinea"
            ? 3 //moneda id
            : null,
      },
      wallet:
        selectedPaymentOption === "Pago con Crypto moneda" && walletDigits,
      uuid: null,
      orderTotal: parseInt(total),
      items: itemsInCart,
      currency: currentStore[0].currency.id,
      seller: currentStore[0].plId,
      sellerAmount: parseInt(price),
      shippingAmount:
        selectedShippingOption === "Entrega" ? parseInt(deliveryFee) : 0,
      total: parseInt(total),
      activeOffersIds: null,
      discountCoupon:
        selectedPaymentOption === "Pago con PagoLinea"
          ? price * storeDiscount
          : "",
      buyer: 3336,
      app: 1,
    };
    console.log('objeto de la orden: ', order)
    if (selectedPaymentOption === "Pago con PagoLinea") {
      if (selectedCurrency === 0 || selectedCurrency === "0") {
        setIsAlertOpened({
          state: true,
          message: "Debes escoger que divisa usar / Iniciar Sesión",
        });
      } else {
        if (selectedShippingOption === "Entrega") {
          if (coordinates.lat === null) {
            setIsAlertOpened({
              state: true,
              message:
                "Al parecer no has ingresado una dirección valida! Intenta de nuevo",
            });
          } else {
            fetchOrder(order);
          }
        } else {
          fetchOrder(order);
        }
      }
    } else if (selectedPaymentOption === "Pago con Crypto moneda") {
      if (
        walletDigits.toString().length < 6 ||
        walletDigits.toString().length > 6
      ) {
        setIsAlertOpened({
          state: true,
          message:
            "Al parecer no ingresaste los ultimos 6 digitos de tu billetera",
        });
      } else {
        if (selectedShippingOption === "Entrega") {
          if (coordinates.lat === null) {
            setIsAlertOpened({
              state: true,
              message:
                "Al parecer no has ingresado una dirección valida! Intenta de nuevo",
            });
          } else {
            fetchOrder(order);
          }
        } else {
          fetchOrder(order);
        }
      }
    } else if (selectedPaymentOption === "Mercado Pago") {
      console.log(selectedPaymentOption, preferenceId, statusMP)
    } else {
      if (selectedShippingOption === "Entrega") {
        if (coordinates.lat === null) {
          setIsAlertOpened({
            state: true,
            message:
              "Al parecer no has ingresado una dirección valida! Intenta de nuevo",
          });
        } else {
          fetchOrder(order);
        }
      } else {
        fetchOrder(order);
      }
    }
  };

  const handleIframePassword = () => {
    setisIframePasswordVisible(!isIframePasswordVisible);
  };
  const handleIframeRegister = () => {
    setisIframeRegisterVisible(!isIframeRegisterVisible);
  };

  function validateEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  const login = () => {
    if (emailError === true || passwordLogin === "") {
      setIsAlertOpened({
        state: true,
        message:
          "Al parecer nos has ingresado los datos correctos al inicar sesión.",
      });
    } else {
      fetch(`https://api.pagolinea.com/v2/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailLogin,
          password: passwordLogin,
          version: 3,
        }),
      })
        .then((res) => res.json())
        .then((resUser) => {
          if (resUser.code) {
            setLoginError(answerByCode(resUser.code));
          } else {
            setUserToken(localStorage.setItem("user-tk", resUser.token));
            window.location.reload();
            setEmailError(false);
          }
        });
    }
  };
  
  if (isLoaded) {
    return (
      <Formik
        validationSchema={inputValidations}
        initialValues={
          selectedShippingOption === "Entrega"
            ? userToken !== null
              ? {
                  name: userInfo.firstName + " " + userInfo.lastName,
                  email: userInfo.email,
                  postalCode: "",
                  phoneNumber: userInfo.phone,
                }
              : { name: "", email: "", postalCode: "", phoneNumber: "" } //If user is not logged in and it is deliver
            : selectedShippingOption === "Retiro en local"
            ? userToken !== null
              ? {
                  name: userInfo.firstName + " " + userInfo.lastName,
                  email: userInfo.email,
                  phoneNumber: userInfo.phone,
                }
              : {
                  name: "",
                  email: "",
                  phoneNumber: "",
                }
            : selectedShippingOption === "Servicio a la mesa"
            ? userToken !== null
              ? {
                  name: userInfo?.firstName + " " + userInfo?.lastName,
                  tableNumber: "",
                }
              : {
                  name: "",
                  tableNumber: "",
                }
            : selectedPaymentOption === "Pago con PagoLinea" &&
              userToken === null
            ? yup.object({
                emailLogin: yup
                  .string()
                  .required("Requerido")
                  .email("Ingresa email valido"),
                passwordLogin: yup.string().required("Requerido"),
              })
            : null
        }
        onSubmit={() => {
          makeOrder();
        }}
      >
        {(formikprops) => (
          <div className="relative bg-[#F3F4F6]">
            <HiChevronLeft
              className={` text-light-gray text-[40px] absolute top-[2%] left-[2%] cursor-pointer z-[1800] `}
              onClick={() => navigate(-1)}
            />
            <div className="py-[40px] px-[16px] md:px-[50px] lg:px-[100px] flex flex-1 space-y-[100px] lg:space-y-0 lg:space-x-[100px] lg:h-[100vh] flex-col lg:flex-row relative md:overflow-y-scroll">
              {/* Options */}
              <div className="w-full lg:w-[60%] h-full pt-[20px] space-y-[60px] pr-[20px] overflow-y-scroll">
                {/* ** Shipping options ** */}
                <div className="space-y-[30px]">
                  <h3 className="text-2xl text-black">Tipo de envío</h3>
                  <div className="flex space-x-[20px]">
                    {currentStore.length > 0 && currentStore[0].shippingOption.pickup && (
                      <label
                        className="containerLabel"
                        id="1"
                        onClick={() => getShippingValue(1)}
                      >
                        Retiro en local
                        <input
                          type="radio"
                          name={currentStore[0].shippingOption.id}
                          defaultChecked
                        />
                        <span className="checkmarkLabel"></span>
                      </label>
                    )}
                    {currentStore[0].shippingOption.delivery && (
                      <label
                        className="containerLabel"
                        id="2"
                        onClick={() => getShippingValue(2)}
                      >
                        Entrega
                        <input
                          type="radio"
                          name={currentStore[0].shippingOption.id}
                        />
                        <span className="checkmarkLabel"></span>
                      </label>
                    )}
                    {currentStore[0].shippingOption.serviceTable && (
                      <label
                        className="containerLabel"
                        id="3"
                        onClick={() => getShippingValue(3)}
                      >
                        Servicio a la mesa
                        <input
                          type="radio"
                          name={currentStore[0].shippingOption.id}
                        />
                        <span className="checkmarkLabel"></span>
                      </label>
                    )}
                  </div>
                </div>
                {/*** Details ***/}
                <div className="space-y-[30px]">
                  {selectedShippingOption === "Retiro en local" && (
                    <>
                      <h3 className="text-2xl text-black">Información</h3>
                      <div className="flex-1 grid lg:grid-cols-2 gap-[25px]">
                        <FormikInput
                          formikprops={formikprops}
                          formikpropsError={formikprops.errors.name}
                          formikpropsTouched={formikprops.touched.name}
                          label="Nombre y apellido(s)"
                          isRequired
                          value={name}
                          hasIconError
                          onChange={(e) => {
                            const value = e.target.value;
                            setName(value);
                            formikprops.values.name = value;
                          }}
                        />
                        <FormikInput
                          formikprops={formikprops}
                          formikpropsError={formikprops.errors.email}
                          formikpropsTouched={formikprops.touched.email}
                          label="Correo Electrónico"
                          isRequired
                          value={email}
                          hasIconError
                          onChange={(e) => {
                            const value = e.target.value;
                            setEmail(value);
                            formikprops.values.email = value;
                          }}
                        />
                        <FormikInput
                          formikprops={formikprops}
                          formikpropsError={formikprops.errors.phoneNumber}
                          formikpropsTouched={formikprops.touched.phoneNumber}
                          label="Teléfono"
                          isRequired
                          value={phoneNumber}
                          hasIconError
                          onChange={(e) => {
                            const value = e.target.value;
                            setPhoneNumber(value);
                            formikprops.values.phoneNumber = value;
                          }}
                        />
                      </div>
                    </>
                  )}
                  {selectedShippingOption === "Servicio a la mesa" && (
                    <>
                      <h3 className="text-2xl text-black">Información</h3>
                      <div className="flex-1 grid lg:grid-cols-2 gap-[25px]">
                        <FormikInput
                          formikprops={formikprops}
                          formikpropsError={formikprops.errors.name}
                          formikpropsTouched={formikprops.touched.name}
                          label="Nombre y apellido(s)"
                          isRequired
                          value={name}
                          hasIconError
                          onChange={(e) => {
                            const value = e.target.value;
                            setName(value);
                            formikprops.values.name = value;
                          }}
                        />
                        <FormikInput
                          formikprops={formikprops}
                          formikpropsError={formikprops.errors.tableNumber}
                          formikpropsTouched={formikprops.touched.tableNumber}
                          label="Número de mesa"
                          isRequired
                          value={tableNumber}
                          hasIconError
                          onChange={(e) => {
                            const value = e.target.value;
                            setTableNumber(value);
                            formikprops.values.tableNumber = value;
                          }}
                        />
                      </div>
                    </>
                  )}
                  {selectedShippingOption === "Entrega" && (
                    <>
                      <h3 className="text-2xl text-black">Información</h3>
                      <div className="flex-1 grid lg:grid-cols-2 gap-[25px]">
                        {userToken !== null &&
                        isEmptyArray(addresses) === false ? (
                          <>
                            <AddressRadio
                              address={addresses[0]}
                              value={addresses[0]}
                              defaultChecked
                              onClick={() => getAddressValue(addresses[0].id)}
                            />
                            {addresses
                              .filter((item) => item.id !== addresses[0].id)
                              .map((itemMap) => (
                                <AddressRadio
                                  address={itemMap}
                                  value={itemMap}
                                  onClick={() => getAddressValue(itemMap.id)}
                                />
                              ))}
                          </>
                        ) : (
                          <>
                            <FormikInput
                              formikprops={formikprops}
                              formikpropsError={formikprops.errors.name}
                              formikpropsTouched={formikprops.touched.name}
                              label="Nombre y apellido(s)"
                              isRequired
                              value={name}
                              hasIconError
                              onChange={(e) => {
                                const value = e.target.value;
                                setName(value);
                                formikprops.values.name = value;
                              }}
                            />
                            <FormikInput
                              formikprops={formikprops}
                              formikpropsError={formikprops.errors.email}
                              formikpropsTouched={formikprops.touched.email}
                              label="Correo electrónico"
                              isRequired
                              value={email}
                              hasIconError
                              onChange={(e) => {
                                const value = e.target.value;
                                setEmail(value);
                                formikprops.values.email = value;
                              }}
                            />
                            <PlacesAutocomplete
                              value={street}
                              onSelect={handleSelect}
                              onChange={setStreet}
                              searchOptions={{
                                componentRestrictions: { country: "ar" },
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <label className="flex flex-col text-sm text-black relative">
                                  Calle y número: *{" "}
                                  <input
                                    {...getInputProps({})}
                                    className={`w-full p-[10px] bg-transparent border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-none ${
                                      suggestions.length > 0
                                        ? "rounded-t-lg"
                                        : "rounded-lg"
                                    }`}
                                  />
                                  {suggestions.length > 0 && (
                                    <div className="bg-white absolute left-0 right-0 mx-auto top-[98%] rounded-b-lg border-[1px] border-light-gray z-[1220]">
                                      {loading && <span>...loading</span>}{" "}
                                      {suggestions.map((suggestion, i) => {
                                        return (
                                          <div
                                            className={`text-black border-b-[1px] border-light-gray w-full p-[10px] flex items-center space-x-[5px] hover:bg-secondary-purple cursor-pointer  ${
                                              suggestion.active &&
                                              "bg-secondary-purple"
                                            }`}
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {}
                                            )}
                                            key={i}
                                          >
                                            <MdLocationPin className="text-base text-main-purple" />
                                            <span className="flex-1">
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </label>
                              )}
                            </PlacesAutocomplete>

                            <FormikInput
                              label="Apto/Piso/Entre calles"
                              value={details}
                              onChange={(e) => {
                                const value = e.target.value;
                                setDetails(value);
                              }}
                            />
                            <FormikInput
                              formikprops={formikprops}
                              formikpropsError={formikprops.errors.postalCode}
                              formikpropsTouched={
                                formikprops.touched.postalCode
                              }
                              label="Código postal"
                              isRequired
                              value={postalCode}
                              hasIconError
                              onChange={(e) => {
                                const value = e.target.value;
                                setPostalCode(value);
                                formikprops.values.postalCode = value;
                              }}
                            />
                            <FormikInput
                              formikprops={formikprops}
                              formikpropsError={formikprops.errors.phoneNumber}
                              formikpropsTouched={
                                formikprops.touched.phoneNumber
                              }
                              label="Teléfono"
                              isRequired
                              value={phoneNumber}
                              hasIconError
                              onChange={(e) => {
                                const value = e.target.value;
                                setPhoneNumber(value);
                                formikprops.values.phoneNumber = value;
                              }}
                            />

                            <div>
                              <span className="text-sm text-black">
                                Barrio:
                              </span>
                              <select
                                className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                                id="select"
                                onChange={handleChangeNeighbourhood}
                              >
                                {neighbourhoods.map((item) => (
                                  <option key={item.id} value={item.name}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              <span className="text-[10px] text-light-gray">
                                Solo se aceptan pedidos dentro de CABA.
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>

                {/* Payment options */}
                <h3 className="text-2xl text-black">Métodos de pago</h3>
                <div className="grid gap-[20px] grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                  <label
                    className="containerLabel"
                    id="4"
                    onClick={() => getPaymentValue(4)}
                  >
                    Pago con PagoLinea
                    <input type="radio" name="payment" />
                    <span className="checkmarkLabel"></span>
                  </label>
                  <label
                    className="containerLabel"
                    id="5"
                    onClick={() => getPaymentValue(5)}
                  >
                    Efectivo
                    <input type="radio" name="payment" defaultChecked />
                    <span className="checkmarkLabel"></span>
                  </label>
                  <label
                    className="containerLabel"
                    id="6"
                    onClick={() => getPaymentValue(6)}
                  >
                    Pago con Crypto moneda
                    <input type="radio" name="payment" />
                    <span className="checkmarkLabel"></span>
                  </label>

                  {/* <label
                    className="containerLabel"
                    id="8"
                    onClick={() => getPaymentValue(8)}
                  >
                    Pago con Mercado pago
                    <input type="radio" name="payment" />
                    <span className="checkmarkLabel"></span>
                  </label> */}

                  {selectedShippingOption !== "Entrega" && (
                    <label
                      className="containerLabel"
                      id="7"
                      onClick={() => getPaymentValue(7)}
                    >
                      Mercado Pago
                      <input type="radio" name="payment" />
                      <span className="checkmarkLabel"></span>
                    </label>
                  )}
                </div>

                {/* Login Show */}

                {selectedPaymentOption === "Pago con PagoLinea" && (
                  <div
                    className="w-full flex justify-center items-center flex-col"
                    ref={myRef}
                  >
                    <div
                      className={`p-[50px] space-y-[30px] bg-white w-[80%] lg:w-[60%] shadow-lg ${
                        orderError !== "" ? "rounded-t-xl" : "rounded-xl"
                      }`}
                    >
                      {userToken === null && (
                        <>
                          <h3 className="text-2xl text-black text-center">
                            Inicia Sesion
                          </h3>

                          {/* INPUT */}

                          <div className="w-full space-y-[15px]">
                            <label className="flex flex-col text-sm text-black w-full">
                              <div className="flex w-full items-center">
                                <span className="text-black">
                                  Correo Electrónico:{" "}
                                  <span className="text-main-purple">*</span>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                                onChange={(e) => {
                                  if (validateEmail(e.target.value)) {
                                    setEmailError(false);
                                  } else {
                                    setEmailError(true);
                                  }
                                  setEmailLogin(e.target.value);
                                }}
                              />
                            </label>
                            <label className="flex flex-col text-sm text-black w-full">
                              <div className="flex w-full items-center">
                                <span className="text-black">Contraseña:</span>{" "}
                                <span className="text-main-purple">*</span>
                              </div>
                              <input
                                type="password"
                                className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                                onChange={(e) =>
                                  setPasswordLogin(e.target.value)
                                }
                              />
                            </label>
                          </div>
                          {/* BUTTON */}
                          <div className="space-y-[15px]">
                            <button
                              className="bg-main-purple w-full py-[10px] rounded-tl-xl rounded-br-xl text-white"
                              onClick={() => login()}
                            >
                              Ingresar
                            </button>
                            {loginError !== "" && (
                              <span className="text-alert text-sm">
                                {loginError}
                              </span>
                            )}
                            <div className="flex flex-col justify-center items-center">
                              <span
                                className="text-sm text-black cursor-pointer"
                                onClick={() => handleIframeRegister()}
                              >
                                ¿No tenes cuenta?{" "}
                                <span className="text-main-purple">
                                  Registrate ahora
                                </span>
                              </span>

                              <span
                                className="text-sm text-black cursor-pointer"
                                onClick={() => handleIframePassword()}
                              >
                                ¿Olvidates tu contraseña?
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {userToken !== null && (
                        <div className="flex flex-col justify-center items-center space-y-[16px]">
                          <h3 className="text-2xl text-black text-center">
                            Billetera a utilizar
                          </h3>
                          <label className="flex flex-col text-sm text-black relative">
                            <select
                              className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                              id="selectCurrency"
                              onChange={handleChangeCurrency}
                            >
                              <option value="0">Selecciona una divisa</option>
                              {currencies.map((item, i) => (
                                <option key={i} value={item.id}>
                                  <MdLocationPin className="text-base text-main-purple" />
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                      )}
                    </div>

                    {/*Order Error*/}
                    {orderError !== "" && (
                      <div className="w-[60%] bg-red-100 text-center">
                        <span className="text-alert file:">{orderError}</span>
                      </div>
                    )}
                  </div>
                )}

                {/* Select crypto */}
                {selectedPaymentOption === "Pago con Crypto moneda" &&  selectedCrypto.state === true ? (
                  <div
                    className="w-full flex justify-center items-center flex-col"
                    ref={myRef}
                  >
                    <div
                      className={`p-[20px] space-y-[20px] bg-white w-[75%] shadow-lg rounded-xl relative `}
                    >
                      <div className="absolute top-[16px] left-[16px] cursor-pointer">
                        <motion.div whileHover="hover" initial="rest">
                          <BsInfoCircle className="text-main-purple text-2xl" />
                          <motion.div
                            className="bg-secondary-purple rounded-xl w-[50%] p-[20px] "
                            style={{
                              boxShadow: "rgba(99, 11, 134, 0.05) 0px 8px 24px",
                            }}
                            variants={infoMotion}
                          >
                            <span className="text-black text-sm">
                              Escanea el QR para copiar la direccion. Recuerda
                              que debes ingresar a tu wallet preferida, hacer el
                              pago y confirmarlo en el boton de abajo.
                            </span>
                          </motion.div>
                        </motion.div>
                      </div>
                      {/* TITLE */}
                      <div className="flex items-center w-full justify-center space-x-[15px]">
                        <BsWallet2 className="text-main-purple text-2xl" />
                        <h3 className="text-2xl text-main-purple front-semibold">
                          {selectedCrypto.data.currency.name}
                        </h3>
                      </div>

                      {/* INFO */}
                      <div className="w-full flex justify-between h-full space-y-[20px] lg:space-y-0 lg:space-x-[20px] flex-col lg:flex-row">
                        {/* QR Code */}
                        <div className="w-full lg:w-[40%] h-full">
                          <QRCode
                            value={selectedCrypto.data.address}
                            className="w-full h-auto"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>

                        <div className="w-full lg:w-[60%] h-full space-y-[15px] flex flex-col justify-center">
                          <motion.div
                            className="p-[10px] rounded-xl bg-white border-[1px] border-light-gray text-light-gray overflow-x-scroll cursor-copy"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedCrypto.data.address
                              );
                            }}
                            whileTap={{ backgroundColor: "#F7EEFB" }}
                            transition={{ duration: 0.1 }}
                          >
                            <span>{selectedCrypto.data.address}</span>
                          </motion.div>
                          <div
                            className="bg-white rounded-xl w-full h-full p-[10px] flex flex-col justify-center items-center space-y-[20px]"
                            style={{
                              boxShadow: "rgba(99, 11, 134, 0.05) 0px 8px 8px",
                              background:
                                "linear-gradient(103.48deg, #FFFFFF -2.23%, rgba(247, 238, 251, 0.62) 101.11%)",
                            }}
                          >
                            <h3 className="text-black font-semibold">
                              Taza de cambio
                            </h3>

                            <div className="w-full h-full grid grid-cols-2 place-content-center p-[4px]">
                              <div className="flex flex-row space-x-[15px]">
                                <img
                                  src={cryptoImg(currentStore[0].currency.code)}
                                  alt="Crypto"
                                  className="w-[20px] h-[20px]"
                                />
                                <span className="text-black">
                                  {currentStore[0].currency.code}
                                </span>
                              </div>
                              <div className="flex flex-row space-x-[15px]">
                                <img
                                  src={cryptoImg(
                                    selectedCrypto.data.currency.code
                                  )}
                                  alt="Crypto"
                                  className="w-[20px] h-[20px]"
                                />
                                <span className="text-black overflow-x-scroll">
                                  {selectedCrypto.data.currency.name}
                                </span>
                              </div>
                              <span className="text-black">
                                {total} {currentStore[0].currency.code}
                              </span>
                              <span className="text-black">
                                {exchangeRate.toFixed(6)}{" "}
                                {selectedCrypto.data.currency.code}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* INPUTS */}
                      <div className="w-full flex space-y-[20px] lg:space-y-0 lg:space-x-[20px] flex-col lg:flex-row">
                        <label className="flex w-full lg:w-[40%] text-sm text-black flex-col">
                          Cambiar crypto:
                          <select
                            className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                            id="selectCrypto"
                            onChange={handleChangeCryptoCurrency}
                          >
                            {cryptoCurrencies.map((item) => (
                              <option key={item.id} value={item.currency.name}>
                                {item.currency.name}
                              </option>
                            ))}
                          </select>
                        </label>
                        <FormikInput
                          formikprops={formikprops}
                          formikpropsError={formikprops.errors.walletDigits}
                          formikpropsTouched={formikprops.touched.walletDigits}
                          value={walletDigits}
                          label="Ultimos 6 digitos de tu wallet"
                          hasIconError
                          isRequired
                          onChange={(e) => {
                            const value = e.target.value;
                            setWalletDigits(value);
                            formikprops.values.walletDigits = value;
                          }}
                        />
                      </div>

                      {/* BUTTON */}
                      <button
                        className="w-full bg-main-purple rounded-tl-xl rounded-br-xl text-white p-[10px]"
                        onClick={() => formikprops.handleSubmit()}
                        type="submit"
                      >
                        Confirmar pago
                      </button>
                    </div>
                  </div>
                ) : selectedPaymentOption === "Pago con Crypto moneda" &&
                  selectedCrypto.state === false ? (
                  <div className="relative w-full h-[300px]" ref={myRef}>
                    <div className="absolute bottom-0 top-0 right-0 left-0 m-auto w-[50px] h-[50px]">
                      <span className="loader"></span>
                    </div>
                  </div>
                ) : null}
              </div>

              {/*** CART ***/}
              <div className="bg-white w-full lg:w-[40%] h-full p-[21px] rounded-xl border-[1px] border-[#E6EBF1] flex flex-col justify-between flex-1">
                <div className="border-b-[1px] border-[#F3F4F6] pb-[20px] flex-[0.1] flex flex-col items-center justify-center">
                  <h3 className="text-xl text-black">Resumen de tu pedido</h3>
                  <span className="text-[12px] text-light-gray">
                    {currentStore[0].name}
                  </span>
                </div>

                <div className="flex flex-col justify-start flex-[0.7] space-y-[30px] border-b-[1px] border-[#E6EBF1] overflow-y-scroll py-[30px]">
                  {itemsInCart.map((item) => (
                    <ItemInCartCard product={item} key={item.id} />
                  ))}
                </div>
                <div className="flex-[0.2] flex justify-center flex-col space-y-[10px]">
                  <div className="flex justify-between items-center w-full">
                    <p className="text-light-gray text-sm">
                      Total en el carrito:{" "}
                    </p>
                    <div className="space-x-[10px]">
                      <span className="text-sm text-light-gray">
                        {currentStore[0].currency.code}
                        {price}
                      </span>
                    </div>
                  </div>
                  {deliveryFee > 0 && selectedShippingOption === "Entrega" && (
                    <div className="flex justify-between items-center w-full">
                      <p className="text-light-gray text-sm">COSTO DE ENVÍO:</p>
                      <div className="space-x-[10px]">
                        <span className="text-sm text-light-gray">
                          {currentStore[0].currency.code}
                          {deliveryFee}
                        </span>
                      </div>
                    </div>
                  )}
                  {storeDiscount > 0 && (
                    <div className="flex justify-between items-center w-full">
                      <p className="text-[#4AA36B] text-sm">DESCUENTO:</p>
                      <div className="space-x-[10px]">
                        <span className="text-sm text-[#4AA36B]">
                          -{currentStore[0].currency.code}
                          {price * storeDiscount}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="flex justify-between items-center w-full font-bold">
                    <p className="text-light-gray">TOTAL:</p>
                    <div className="space-x-[10px]">
                      {currentStore[0].currency.code}
                      <span className="text-black ">{total}</span>
                    </div>
                  </div>
                  {((selectedPaymentOption !== "Mercado Pago" && preferenceId !== "") || statusMP) ?
                    <button
                      className="w-full bg-main-purple rounded-tl-xl rounded-br-xl text-white p-[10px]"
                      onClick={() => formikprops.handleSubmit()}
                      type="submit"
                    >
                      Hacer pedido
                    </button> 
                  :
                    <Checkout
                      preferenceId={preferenceId}
                    />
                   }
                </div>

                <span className="text-[10px] text-light-gray text-center">
                  Efectuando la compra usted está aceptando nuestro{" "}
                  <span className="text-main-purple">
                    Terminos y condiciones.
                  </span>
                </span>
              </div>

              <motion.div
                className="absolute left-[-5%] bottom-[50%]"
                initial={{ bottom: "50%" }}
                animate={{ bottom: "40%" }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "mirror",
                }}
              >
                <HiOutlineArrowNarrowDown
                  className={` ${
                    orderError !== "" &&
                    selectedPaymentOption === "Pago con PagoLinea"
                      ? "text-alert text-5xl"
                      : "text-light-gray text-xl"
                  }`}
                />
              </motion.div>
            </div>

            {/* Iframe */}
            {/* <
              isIframeRegisterVisible = {isIframeRegisterVisible}
              handleIframeRegister = {handleIframeRegister}
            /> */}
            

            {/* Success order */}
            {isSuccessPopUpActive === true && (
              <SuccessPopUp
                isSuccessPopUpActive={isSuccessPopUpActive}
                setIsSuccessPopUpActive={setIsSuccessPopUpActive}
              />
            )}

            <CheckoutAlert
              setIsAlertOpened={setIsAlertOpened}
              isAlertOpened={isAlertOpened}
            />
          </div>
        )}
      </Formik>
    );
  } else {
    return (
      <div className="w-[100vw] h-[100vh] cursor-progress">
        <div className="absolute bottom-0 top-0 right-0 left-0 m-auto w-[50px] h-[50px]">
          <span className="loader"></span>
        </div>
      </div>
    );
  }
}

export default Pedido;
