// import tronLogo from "../assets/images/tronLogo.svg";
// import bitcoinLogo from "../assets/images/bitcoinLogo.svg";
// import usdtLogo from "../assets/images/tetherLogo.svg";

export default function cryptoImg(crypto) {
  switch (crypto) {
    case "BTC":
      return "https://cryptologos.cc/logos/bitcoin-btc-logo.svg?v=024";
      break;
    case "USDT":
      return "https://cryptologos.cc/logos/tether-usdt-logo.svg?v=024";
      break;
    case "TRX":
      return "https://cryptologos.cc/logos/tron-trx-logo.svg?v=024";
      break;
    case "ARS":
      return "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg";
      break;
    case "USD":
      return "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg";
      break;
    default:
      return "NONE";
      break;
  }
}
