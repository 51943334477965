import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useCartContext } from "../context/CartProvider";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import UserBasicInfo from "../components/user/UserBasicInfo";
import bg from "../assets/images/userBg.svg";
import { TiBusinessCard, TiMap, TiPlusOutline } from "react-icons/ti";
import UserAddress from "../components/user/UserAddress";
import { Formik } from "formik";
import * as yup from "yup";
import FormikInput from "../components/FormikInput";
import { IoIosExit } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { motion } from "framer-motion";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapApiJs = "https://maps.googleapis.com/maps/api/js";

//load google map api js

function Usuario() {
  const navigate = useNavigate();
  const { userToken, setUserToken, userInfo, setUserInfo } = useCartContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [addressName, setAddressName] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressAdition, setAddressAdittion] = useState("");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [resultsMapsApi, setResultsMapsApi] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("user-tk")) {
      const tokenLS = localStorage.getItem("user-tk");
      setUserToken(tokenLS);
    } else {
      setUserToken(null);
    }
  }, []);

  useEffect(() => {
    if (userToken !== null) {
      fetch(`https://api.pagolinea.com/v2/address`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((res) => res.json())
        .then((resJSON) => {
          setAddresses(resJSON.data);
          setIsLoaded(true);
        });
    }
  }, [userToken]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setCoordinates(latLng);
    setAddressStreet(value);
    setResultsMapsApi(results);
  };

  const addNewAddressValidations = yup.object({
    addressName: yup.string("Debe ser texto").required("Requerido"),
    addressAdition: yup.string("Debe ser texto").required("Requerido"),
  });

  const deleteAddress = (addressId) => {
    const newAddresses = addresses.filter(
      (address) => address.id !== addressId
    );

    setAddresses(newAddresses);
    fetch(`https://api.pagolinea.com/v2/address/${addressId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((resJSON) => {});
  };

  const addAddress = () => {
    if (coordinates.lat === null) {
      alert("Debes ingresar una dirección valida");
    } else {
      fetch(`https://api.pagolinea.com/v2/address`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          city: "CABA",
          countryId: 259,
          latitude: coordinates.lat,
          lineOne: resultsMapsApi[0].formatted_address,
          lineTwo: addressAdition,
          longitude: coordinates.lng,
          name: addressName,
          zipCode: 1234,
        }),
      })
        .then((res) => res.json())
        .then((resJSON) => setAddresses([...addresses, resJSON.data]))
        .catch((error) => {
          console.log("error add addresss", error);
        });
    }
  };

  const signOut = () => {
    localStorage.removeItem("user-tk");
    navigate("/tiendas");
    window.location.reload();
  };

  if (userToken !== null) {
    if (isLoaded === true) {
      return (
        <div
          style={{
            backgroundImage: `url(${bg}) `,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
          }}
          className="w-full h-full relative"
        >
          <NavBar />
          <div className="py-[40px] px-[16px] md:px-[50px] lg:px-[100px] space-y-[60px] flex-1 flex flex-col justify-center items-center">
            <div className="w-full">
              <h3 className="text-black text-4xl lg:text-5xl">
                Hola, {userInfo.firstName}
              </h3>
            </div>
            <div className="w-full lg:w-[70%] h-full space-y-[50px] ">
              <div className="space-y-[16px]">
                <div className="text-black text-2xl lg:text-3xl flex items-center space-x-[10px]">
                  <TiBusinessCard />
                  <h3>Tu información básica:</h3>
                </div>
                <div className="flex flex-col lg:flex-row w-full justify-between">
                  <UserBasicInfo title="Nombre y apellido:" user={userInfo} />
                  <UserBasicInfo title="Email:" user={userInfo} email />
                </div>
              </div>
              <div className="space-y-[16px]">
                <div className="text-black text-2xl lg:text-3xl flex items-center space-x-[10px]">
                  <TiMap />
                  <h3>Tus direcciones:</h3>
                </div>
                <div className="w-full h-full bg-white border-light-gray border-[1px] flex flex-col justify-start p-[20px] flex-1 space-y-[20px] rounded-[20px] shadow-sm">
                  <ul className="flex justify-between text-gray flex-1">
                    <li className="flex-[2]">Nombre</li>
                    <li className="flex-[6]">Dirección</li>
                    <li className="flex-[2]">Casa/Apto/Depto</li>
                  </ul>
                  <ul className="space-y-[10px]">
                    {addresses.map((addressInfo, i) => (
                      <UserAddress
                        address={addressInfo}
                        key={i}
                        onClick={() => {
                          deleteAddress(addressInfo.id);
                        }}
                      />
                    ))}
                  </ul>
                </div>
              </div>
              <div className="space-y-[16px]">
                <div className="text-black text-2xl lg:text-3xl flex items-center space-x-[10px]">
                  <TiPlusOutline />
                  <h3>Agregar una nueva dirección</h3>
                </div>
                <Formik
                  initialValues={{
                    addressName: "",
                    addressAdition: "",
                  }}
                  validationSchema={addNewAddressValidations}
                  onSubmit={() => {
                    if (addressStreet !== "") {
                      addAddress();
                    }
                  }}
                >
                  {(formikprops) => (
                    <div className="flex flex-col space-y-[20px]">
                      <div className="grid lg:grid-cols-2 gap-[25px]">
                        <FormikInput
                          formikprops={formikprops}
                          formikpropsTouched={formikprops.touched.addressName}
                          formikpropsError={formikprops.errors.addressName}
                          label="Nombre"
                          value={addressName}
                          onChange={(e) => {
                            const value = e.target.value;
                            setAddressName(value);
                            formikprops.values.addressName = value;
                          }}
                          hasIconError
                          isRequired
                        />

                        <PlacesAutocomplete
                          value={addressStreet}
                          onSelect={handleSelect}
                          onChange={setAddressStreet}
                          searchOptions={{
                            componentRestrictions: { country: "ar" },
                          }}
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => (
                            <label className="flex flex-col text-sm text-black relative">
                              Calle y número: *
                              <input
                                {...getInputProps({ placeholder: "Dirección" })}
                                className={`w-full p-[10px] bg-transparent border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-none ${
                                  suggestions.length > 0
                                    ? "rounded-t-lg"
                                    : "rounded-lg"
                                }`}
                              />
                              {suggestions.length > 0 && (
                                <div className="bg-white absolute left-0 right-0 mx-auto top-[98%] rounded-b-lg border-[1px] border-light-gray">
                                  {loading && <span>...loading</span>}{" "}
                                  {suggestions.map((suggestion) => {
                                    return (
                                      <div
                                        className={`text-black border-b-[1px] border-light-gray w-full p-[10px] flex items-center space-x-[5px] hover:bg-secondary-purple cursor-pointer ${
                                          suggestion.active &&
                                          "bg-secondary-purple"
                                        }`}
                                        {...getSuggestionItemProps(
                                          suggestion,
                                          {}
                                        )}
                                      >
                                        <MdLocationPin className="text-base text-main-purple" />
                                        <span className="flex-1">
                                          {suggestion.description}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </label>
                          )}
                        </PlacesAutocomplete>

                        <FormikInput
                          formikprops={formikprops}
                          formikpropsTouched={
                            formikprops.touched.addressAdition
                          }
                          formikpropsError={formikprops.errors.addressAdition}
                          label="Nro. Casa/Piso/Depto"
                          value={addressAdition}
                          onChange={(e) => {
                            const value = e.target.value;
                            setAddressAdittion(value);
                            formikprops.values.addressAdition = value;
                          }}
                          hasIconError
                          isRequired
                        />
                      </div>

                      <div>
                        <motion.button
                          onClick={() => formikprops.handleSubmit()}
                          type="submit"
                          className="bg-main-purple py-[10px] px-[20px] rounded-tl-xl rounded-br-xl text-white"
                          whileHover={{ backgroundColor: "#44045E" }}
                          whileTap={{ scale: 0.95 }}
                        >
                          Agregar
                        </motion.button>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <Footer />
          <motion.div
            className="w-[60px] h-[60px] bg-white shadow-xl fixed bottom-[50px] right-[50px] rounded-full grid place-content-center cursor-pointer text-light-gray"
            onClick={signOut}
            whileHover={{ scale: 1.1, color: "#640A88" }}
            whileTap={{ scale: 0.95 }}
          >
            <IoIosExit className="text-[30px] " />
          </motion.div>
        </div>
      );
    } else {
      return (
        <div className="w-[100vw] h-[100vh] cursor-progress">
          <div className="absolute bottom-0 top-0 right-0 left-0 m-auto w-[50px] h-[50px]">
            <span className="loader"></span>
          </div>
        </div>
      );
    }
  } else {
    navigate("/tiendas");
  }
}

export default Usuario;
