export const InstructionsInfo = [
  {
    icon: 0,
    title: "Agregá tu producto al carrito",
    description:
      "Seleccioná tu producto dentro de las categorías o tiendas que te ofrecemos y añádelo al carrito.",
  },
  {
    icon: 1,
    title: "Pagá",
    description:
      "Realizá tu pago de manera rápida y sencilla usando Pago Línea.",
  },
  {
    icon: 2,
    title: "Recibí o recogé tu pedido",
    description:
      "En dependencia si el producto es con envío o no podes pasar a buscarlo o esperarlo en tu casa.",
  },
];
