import React, { createContext, useContext, useEffect, useState } from "react";

const CartContext = createContext();

const getLocalCartData = () => {
  let localCartData = localStorage.getItem("cart");
  if (
    localCartData === [] ||
    localCartData === "null" ||
    localCartData === undefined ||
    !localCartData
  ) {
    return [];
  } else {
    return JSON.parse(localCartData);
  }
};

function CartProvider({ children }) {
  const [itemsInCart, setItemsInCart] = useState(getLocalCartData());
  const [price, setPrice] = useState(0);
  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const [userToken, setUserToken] = useState(null);
  const [userInfo, setUserInfo] = useState([]);
  const [orderNumber, setOrderNumber] = useState(null);
  const [isCartActive, setIsCartActive] = useState(false);
  const [stores, setStores] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAlertAdded, setIsAlertAdded] = useState({
    state: false,
    message: "",
    style: "cart",
  });

  useEffect(() => {
    if (localStorage.getItem("user-tk")) {
      const tokenLS = localStorage.getItem("user-tk");
      setUserToken(tokenLS);
    } else {
      setUserToken(null);
    }
  }, []);

  useEffect(() => {
    if (isAlertAdded.message !== "") {
      const timeoutId = setTimeout(() => {
        setIsAlertAdded({ state: false, message: "" });
      }, 5000);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
    }
  }, [isAlertAdded]);

  useEffect(() => {
    fetch("https://services.pagotienda.com/store/259")
      .then((res) => res.json())
      .then((resStores) => {
        setStores(resStores);
        setIsLoaded(true);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (userToken !== null) {
      fetch(`https://api.pagolinea.com/v2/user/profile`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((res) => res.json())
        .then((resJSON) => {
          if(resJSON.code === 200){
            setUserInfo(resJSON.data);
          }else{
            console.log(resJSON)
          }
        })
        .catch((err) => console.error(err));
    }
  }, [userToken]);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(itemsInCart));
  }, [itemsInCart]);

  return (
    <CartContext.Provider
      value={{
        itemsInCart,
        setItemsInCart,
        price,
        setPrice,
        isAlertOpened,
        setIsAlertOpened,
        isAlertAdded,
        setIsAlertAdded,
        userToken,
        setUserToken,
        userInfo,
        setUserInfo,
        setOrderNumber,
        orderNumber,
        setIsCartActive,
        isCartActive,
        stores,
        setStores,
        isLoaded,
        setIsLoaded,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export const useCartContext = () => useContext(CartContext);

export default CartProvider;
