import React from "react";
import { FaStar } from "react-icons/fa";
import { motion } from "framer-motion";

function TestimonyCard({ photo, name, amountStars, description }) {
  return (
    <motion.div
      className="text-black bg-[#FBFBFD] rounded-2xl w-[300px] h-[300px] p-[20px] space-y-[30px] duration-200"
      whileHover={{ scale: 1.1 }}
    >
      <div className="flex space-x-[10px]">
        <img
          src={photo}
          alt={`${name} foto de perfil`}
          className="rounded-full max-w-[60px] max-h-[60px]"
        />
        <div className="overflow-y-scroll">
          <p>{name}</p>
          <div className="flex">
            {amountStars === 1 ? (
              <FaStar className="text-main-purple" />
            ) : amountStars === 2 ? (
              <>
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
              </>
            ) : amountStars === 3 ? (
              <>
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
              </>
            ) : amountStars === 4 ? (
              <>
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
              </>
            ) : amountStars === 5 ? (
              <>
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
                <FaStar className="text-main-purple" />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <p>{description}</p>
    </motion.div>
  );
}

export default TestimonyCard;
