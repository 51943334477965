import React from "react";
import NavBar from "../components/NavBar";
import Hero from "../components/home/Hero";
import Dowload from "../components/home/Dowload";
import Footer from "../components/Footer";
import InstructionsSection from "../components/home/Instructions/InstructionsSection";
import RestaurantSection from "../components/home/Restaurants/RestaurantSection";
import ProductsSection from "../components/home/ProductsSection";
import TestimonySection from "../components/home/testimonies/TestimonySection";
import { motion } from "framer-motion";

function Home() {
  return (
    <motion.div
      className="bg-[#F7F8F9]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0 }}
    >
      <div className=" w-full flex flex-col justify-center bg-white">
        <NavBar />
        <Hero />
      </div>
      <Dowload />
      <InstructionsSection />
      <RestaurantSection />
      <ProductsSection />
      <TestimonySection />

      <Footer />
    </motion.div>
  );
}

export default Home;
