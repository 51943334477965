import React, { useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

function FormikInput({
  formikprops,
  formikpropsError,
  formikpropsTouched,
  value,
  label,
  onChange,
  hasIconError = false,
  isRequired = false,
  width = "w-full",
  name,
}) {
  return (
    <label className="flex flex-col text-sm text-black">
      <div className="flex w-full items-center ">
        <span className="text-black">
          {label}: <span className="text-main-purple">{isRequired && "*"}</span>
        </span>
        {formikpropsTouched && formikpropsError && (
          <AiOutlineInfoCircle className="text-alert ml-[10px]" />
        )}
      </div>
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={`${width} p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple`}
        name={name}
      />
    </label>
  );
}

export default FormikInput;
