import React, { useState, useEffect } from "react";

const Carrousel = ({
  images = [],
  autoPlay = false,
  duration = 2000,
  isAlone = true,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(() => {
        selectNewImage(selectedIndex, images);
      }, duration);
      return () => clearInterval(interval);
    }
  });

  const selectNewImage = (index, image, next = true) => {
    setLoaded(false);
    setTimeout(() => {
      const conditional = next
        ? selectedIndex < image.length - 1
        : selectedIndex > 0;

      const nextIndex = next
        ? conditional
          ? selectedIndex + 1
          : 0
        : conditional
        ? selectedIndex - 1
        : image.length - 1;

      setSelectedImage(image[nextIndex]);
      setSelectedIndex(nextIndex);
    }, 500);
  };

  if (isAlone === true) {
    return (
      <div className="w-[100%] h-[400px] md:order-2">
        <img
          src={require(`../assets/images/${selectedImage}`)}
          alt="Producto"
          className={
            loaded
              ? "w-full h-full duration-500 opacity-100 z-[0]"
              : "w-full h-full duration-500 opacity-0 z-[0]"
          }
          onLoad={() => setLoaded(true)}
        />
      </div>
    );
  }

  if (isAlone === false) {
    return (
      <div className="w-[100%] md:w-[40%] lg:w-[50%] h-[400px] md:order-2">
        <img
          src={require(`../assets/images/${selectedImage}`)}
          alt="Producto"
          className={
            loaded
              ? "w-full h-full duration-500 opacity-100"
              : "w-full h-full duration-500 opacity-0"
          }
          onLoad={() => setLoaded(true)}
        />
      </div>
    );
  }
};

export default Carrousel;
