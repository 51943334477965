import React, { useEffect, Fragment } from 'react';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
//TEST-400b58b0-d4dc-4913-8ed3-6010c504d5f7 ->test
//APP_USR-4ea751dd-4daa-40c0-bb98-e0ae7e0d4926 ->prod
const Checkout = ({ preferenceId }) => {
  useEffect(() => {
    // Realizar la inicialización de Mercado Pago solo una vez cuando el componente se monta
    initMercadoPago('APP_USR-1369c9cc-3e7e-41d1-a4e1-df0824718b8a');
  }, [preferenceId]);
  console.log(preferenceId)
  return (
    <div style={{with:'60%', padding: '10px',margin:'10px'}}>
      {preferenceId &&
        <Wallet initialization={{ preferenceId: preferenceId , redirectMode: 'modal'}} />
      }
    </div>
  );
};

export default Checkout;