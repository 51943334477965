import React from "react";
import AppStore from "../../assets/images/AppStore.svg";
import GooglePlay from "../../assets/images/GooglePlay.svg";
import Iphone from "../../assets/images/appIphone.webp";
import bg from "../../assets/images/dowloadBg.svg";
import { motion } from "framer-motion";

const textAnimation = {
  offScreen: { y: 50, opacity: 0 },
  onScreen: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", bounce: 0.4, damping: 20 },
  },
};

function Dowload() {
  return (
    <div
      className="py-[50px] px-[16px] md:px-[50px] lg:px-[100px] flex flex-col space-y-[60px] md:flex-row items-center justify-between bg-white"
      style={{
        backgroundImage: `url(${bg}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
      }}
    >
      <motion.div
        className="w-full lg:w-[50%] flex flex-col items-center justify-center space-y-[20px] text-center"
        initial={"offScreen"}
        whileInView={"onScreen"}
        viewport={{ once: false, amount: 0.5 }}
        transition={{ staggerChildren: 0.2 }}
      >
        <motion.h2
          className="text-main-purple text-3xl text-center font-bold md:text-5xl"
          variants={textAnimation}
        >
          ¡Descargá nuestra aplicación móvil!
        </motion.h2>
        <motion.p className="text-black text-xl" variants={textAnimation}>
          Pedí y rastrea tu pedidos a través de tu celular de manera fácil!
        </motion.p>
        <motion.div
          className="flex w-full justify-center space-x-[5%] mt-[30px] mb-[50px]"
          initial={"offScreen"}
          whileInView={"onScreen"}
          viewport={{ once: false, amount: 0.1 }}
          transition={{ staggerChildren: 0.1, delayChildren: 0.5 }}
        >
          <motion.img
            src={AppStore}
            alt="App Store"
            className="w-[40%] lg:w-[20%] cursor-pointer"
            variants={textAnimation}
            whileHover={{
              scale: 1.1,
              transition: { duration: 1 },
            }}
            onClick={() =>
              window.open(
                "https://apps.apple.com/es/app/pagolinea/id1529527977"
              )
            }
          />

          <motion.img
            src={GooglePlay}
            alt="Google Play"
            className="w-[40%] lg:w-[20%] cursor-pointer"
            variants={textAnimation}
            whileHover={{
              scale: 1.1,
              transition: { duration: 1 },
            }}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps/details?id=com.pagolineaapp&pli=1"
              )
            }
          />
        </motion.div>
      </motion.div>
      <div className="w-full lg:w-[50%] ">
        <img src={Iphone} alt="Descargá nuestra app" className="lg:w-[90%]" />
      </div>
    </div>
  );
}

export default Dowload;
