import React from "react";
import { motion } from "framer-motion";

function TopTextAnimated({ title, text }) {
  const h1Animation = {
    offScreen: { y: 100, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, delay: 1 },
    },
  };
  const textAnimation = {
    offScreen: { y: 25, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.2, delay: 1.5 },
    },
  };
  const lineAnimation = {
    offScreen: { width: "0" },
    onScreen: {
      width: "100%",

      transition: { type: "spring", bounce: 0.4, delay: 1.2, duration: 3 },
    },
  };

  return (
    <div className="flex flex-col space-y-[10px] md:flex-row md:space-y-0">
      <motion.h1
        className="font-bold text-4xl md:text-5xl lg:text-6xl text-black md:w-[50%] lg:w-auto lg:mr-[20px]"
        initial={"offScreen"}
        animate={"onScreen"}
        variants={h1Animation}
      >
        {title}
      </motion.h1>
      <div className="flex-1 flex flex-col justify-end lg:justify-center">
        <motion.h4
          className="text-main-purple lg:pl-[10px]"
          initial={"offScreen"}
          animate={"onScreen"}
          variants={textAnimation}
        >
          {text}
        </motion.h4>
        <motion.div
          className="w-[full] h-[2px] bg-main-purple"
          initial={"offScreen"}
          animate={"onScreen"}
          variants={lineAnimation}
        />
      </div>
    </div>
  );
}

export default TopTextAnimated;
