import React from "react";

function ContactInfo({ icon, title, text, additionalInfo = "", text2 }) {
  return (
    <div className="flex flex-col flex-1 space-y-[5px]">
      <div className="flex lg:items-center flex-col lg:flex-row lg:space-x-[10px] lg:space-y-0 space-y-[10px]">
        {icon}
        <h3 className="text-xl text-black">{title}</h3>
      </div>
      {additionalInfo !== "" && (
        <span className="text-light-gray text-[12px]">{additionalInfo}</span>
      )}
      <p className="text-black text-sm">{text}</p>
      <p className="text-black text-sm">{text2}</p>
    </div>
  );
}

export default ContactInfo;
