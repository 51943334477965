import React from "react";
import UseWindowDimensions from "../../../utils/UseWindowDimensions";
import testimonioBg from "../../../assets/images/testimonioBg.svg";
import { TestimoniosInfo } from "../../../utils/TestimoniosInfo";
import TestimonyCard from "./TestimonyCard";
import { motion } from "framer-motion";

function TestimonySection() {
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  const divAnimation = {
    offScreen: { y: 20, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, damping: 50 },
    },
  };
  return (
    <div
      className="py-[40px] px-[16px] md:px-[50px] lg:px-[100px] flex flex-col items-center space-y-[60px]  mt-[10px] bg-white"
      style={{
        backgroundImage: `url(${testimonioBg}) `,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundAttachment: "fixed",
      }}
    >
      <div>
        <h3 className="text-center text-main-purple text-2xl font-bold md:text-4xl">
          Testimonios
        </h3>
        <p className="text-center text-black">
          Algunas opiniones de nuestros usuarios
        </p>
      </div>
      <motion.div
        className="grid md:grid-cols-2 lg:grid-cols-3 gap-[20px]"
        initial={"offScreen"}
        whileInView={"onScreen"}
        viewport={
          desktopWidth
            ? { once: false, amount: 0.5 }
            : { once: false, amount: 0.1 }
        }
        transition={{ staggerChildren: 0.2 }}
      >
        {TestimoniosInfo.map((testimonio, index) => (
          <motion.div variants={divAnimation} key={index}>
            <TestimonyCard
              name={testimonio.name}
              photo={testimonio.photo}
              description={testimonio.description}
              amountStars={testimonio.amountStars}
            />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default TestimonySection;
