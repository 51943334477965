import React from "react";
import { motion } from "framer-motion";
import { FaOpencart } from "react-icons/fa";

function BottomCart({ isCartShown, setIsCartShown, onClick }) {
  return (
    <motion.div
      className={`w-[60px] h-[60px] bg-secondary-purple shadow-xl fixed bottom-[50px] right-[50px] rounded-full grid place-content-center cursor-pointer text-main-purple ${
        isCartShown === false
          ? "opacity-0 duration-200"
          : "opacity-100 duration-400"
      }`}
      onClick={onClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.05 }}
    >
      <FaOpencart className="text-[30px] " />
    </motion.div>
  );
}

export default BottomCart;
