import React from "react";
import { IoMdCloseCircleOutline, IoMdClose } from "react-icons/io";
import { useCartContext } from "../../context/CartProvider";

function Alert() {
  const { setIsAlertOpened, isAlertOpened } = useCartContext();

  const handleAlert = () => {
    setIsAlertOpened(!isAlertOpened);
  };

  return (
    <div
      className={`bg-white shadow-2xl border-l-[10px] border-main-purple fixed top-[10%] flex justify-between items-center py-[20px] px-[10px] rounded-r-xl space-x-[15px] w-[70%] lg:w-[35%] ease-in-out ${
        isAlertOpened ? "left-0 duration-500" : "left-[-100%] duration-500"
      }`}
    >
      <div>
        <IoMdCloseCircleOutline className="text-main-purple text-4xl" />
      </div>
      <div>
        <p className="text-black lg:text-xl font-medium">
          No se puede agregar este producto!
        </p>
        <span className="text-light-gray text-sm lg:text-base">
          Actualmente solo se permite agregar productos de una misma tienda.
        </span>
      </div>
      <div className="cursor-pointer" onClick={() => handleAlert()}>
        <IoMdClose className="text-light-gray" />
      </div>
    </div>
  );
}

export default Alert;
