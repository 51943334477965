import React from "react";
import Restaurant from "./Restaurant";
import UseWindowDimensions from "../../../utils/UseWindowDimensions";
import { motion } from "framer-motion";
import { useCartContext } from "../../../context/CartProvider";

function RestaurantSection() {
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  const divAnimation = {
    offScreen: { y: 100, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, damping: 10 },
    },
  };
  const { stores } = useCartContext();

  return (
    <div className="py-[50px] px-[16px] md:px-[50px] lg:px-[100px] flex flex-col items-center space-y-[60px] bg-white">
      <h3 className="text-center text-main-purple text-2xl font-bold md:text-4xl">
        Restaurantes que confían en Pago Tienda
      </h3>
      <motion.div
        className=" grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-[30px]"
        initial={"offScreen"}
        whileInView={"onScreen"}
        viewport={
          desktopWidth
            ? { once: true, amount: 0.5 }
            : { once: true, amount: 0.3 }
        }
        transition={{ staggerChildren: 0.1 }}
      >
        {stores.map((restaurant, index) => (
          <motion.div variants={divAnimation} key={index}>
            <Restaurant store={restaurant} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
}

export default RestaurantSection;
