import React from "react";
import { motion } from "framer-motion";

function QuestionCard({ icon, title, text }) {
  const contentAnimation = {
    offScreen: { y: 100, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, delay: 2 },
    },
  };
  return (
    <motion.div
      className="bg-white border-[1px] border-[#F2F2F2] rounded-[10px] p-[30px] space-y-[40px]"
      initial={"offScreen"}
      animate={"onScreen"}
      variants={contentAnimation}
    >
      <div className="flex items-center h-[80px]">
        {icon}
        <h3 className="flex-1 text-center text-2xl text-black"> {title}</h3>
      </div>
      <p className="text-gray text-base">{text}</p>
    </motion.div>
  );
}

export default QuestionCard;
