export default function paymentMethod(method) {
  switch (method) {
    case "Pago con PagoLinea":
      return "CREDENTIALS";
      break;
    case "Efectivo":
      return "CASH";
      break;
    case "Pago con Crypto moneda":
      return "CRYPTO";
      break;
    case "Mercado Pago":
      return "Mercado Pago";
      break;
    case "Otros medios de pago":
      return "OTHER";
      break;
    default:
      return "NONE";
      break;
  }
}