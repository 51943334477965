import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import UseWindowDimensions from "../utils/UseWindowDimensions";
import Footer from "../components/Footer";
import TopTextAnimated from "../components/TopTextAnimated";
import QuestionCard from "../components/QuestionCard";
import {
  UilCloudComputing,
  UilCloudUpload,
  UilBill,
} from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import ContactCards from "../components/ContactCards";
import { SiBitcoincash } from "react-icons/si";
import { TbMessageCircle, TbMapPin, TbPhoneCall } from "react-icons/tb";
import ContactInfo from "../components/ContactInfo";

const icons = [
  <UilCloudComputing className="text-[40px] text-main-purple" />,
  <UilCloudUpload className="text-[40px] text-main-purple" />,
  <UilBill className="text-[40px] text-main-purple" />,
  <SiBitcoincash className="text-[40px] text-main-purple" />,
  <TbMessageCircle className="text-[40px] text-main-purple" />,
  <TbPhoneCall className="text-[40px] text-main-purple" />,
  <TbMapPin className="text-[40px] text-main-purple" />,
];

function Aliados() {
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  const [isActive, setIsActive] = useState(false);
  const [subject, setSubject] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [additions, setAdditional] = useState("");
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(document.documentElement.scrollTop);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleActive = (value) => {
    setIsActive(!isActive);
    setSubject(value);
    if (desktopWidth) {
      if (isActive === false) {
        const target = scrollTop + 400;
        const duration = 2000;
        const start = performance.now();
        const ease = (t) =>
          t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

        const animation = (time) => {
          const timeFraction = (time - start) / duration;
          if (timeFraction > 1) return;

          const progress = ease(timeFraction);
          window.scrollTo(0, scrollTop + (target - scrollTop) * progress);
          requestAnimationFrame(animation);
        };

        requestAnimationFrame(animation);
      } else {
        window.scrollBy({
          top: -600,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      exit={{ opacity: 0 }}
    >
      <NavBar />
      <div className="pt-[40px] px-[16px] md:px-[50px] lg:px-[100px] space-y-[60px] pb-[100px]">
        <TopTextAnimated
          title={"Aliados"}
          text={"Queremos que formes parte de nuestro equipo Pago Tienda"}
        />

        <div className=" w-full grid gap-[20px] lg:grid-cols-2">
          <div className="lg:col-span-2">
            <QuestionCard
              title="¿Cómo trabajamos?"
              text="¡Trabajamos de la mano contigo! Sólo debes ser nuestro comercio aliado,
        cargar los productos que tienes disponibles y los usuarios que lleguen a
        Pago Tienda tendrán la opción de pagar a través de nuestra billetera
        digital Pago Línea donde aceptamos divisas y criptomonedas. Realice un
        seguimiento del inventario de cada producto o de cada combinación de
        variantes de producto. Las cantidades de inventario disminuyen
        automáticamente a medida que se realizan las compras. Una vez que el
        inventario de un producto específico llega a cero, el producto se
        mostrará como agotado. Administre y complete pedidos directamente desde
        su dashboard en Pago Tienda. La interfaz fácil de usar muestra todos sus
        pedidos en una vista de tabla, ordenados por los pedidos más recientes
        en la parte superior. Al hacer clic en un pedido, se muestran los
        detalles más finos y la funcionalidad para completar el pedido con unos
        pocos clics. ¡Así de fácil es pertenecer a nuestro equipo PagoTienda!."
              icon={icons[0]}
            />
          </div>
          <div>
            <QuestionCard
              title="¿Cómo publicar mis productos?"
              text="Para publicar sus productos lo único que debe hacer es contactarnos. Nosotros nos encargaremos de iniciar el proceso de registro como comercio de pago en línea, y solicitarle las fotos de sus productos, los detalles y nosotros nos encargamos de todo."
              icon={icons[1]}
            />
          </div>
          <div>
            <QuestionCard
              title="¿Cómo puedo ver el pago de mis productos?"
              text="A través de su perfil de comercio en Pago Tienda y en Pago Línea le brindamos el mejor servicio, por esto tendrá una sección de transacciones donde podrás todas las operaciones en su comercio.ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ"
              icon={icons[2]}
            />
          </div>
        </div>

        <div className="w-full h-full space-y-[100px]">
          <h3 className="text-main-balck text-5xl">Hagamos Equipo!</h3>

          {/* Default options */}
          <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-[30px]">
            <ContactCards
              icon={icons[3]}
              text="¿Cómo funcionan los pagos con Crypto y PagoLinea?"
              isActive={isActive}
              setIsActive={setIsActive}
              onClick={() =>
                handleActive(
                  "¿Cómo funcionan los pagos con Crypto y PagoLinea?"
                )
              }
            />
            <ContactCards
              icon={icons[3]}
              text="¿Cuál es la comisión que recibe el repartidor?"
              isActive={isActive}
              setIsActive={setIsActive}
              onClick={() =>
                handleActive("¿Cuál es la comisión que recibe el repartidor?")
              }
            />
            <ContactCards
              icon={icons[3]}
              text="¿Qué beneficios tiene pagoLinea sobre otras empresas?"
              isActive={isActive}
              setIsActive={setIsActive}
              onClick={() =>
                handleActive(
                  "Qué beneficios tiene pagoLinea sobre otras empresas?"
                )
              }
            />
          </div>

          {/* Contact info */}
          {desktopWidth && isActive ? (
            <div className="w-full flex flex-col lg:flex-row space-y-[30px] lg:space-y-0 lg:space-x-[30px]">
              <div className="w-full lg:w-[40%] grid grid-cols-2 lg:grid-cols-1 gap-[30px]">
                <ContactInfo
                  title="Hablemos por mensaje!"
                  text="+54 9 11 5220-9217"
                  additionalInfo="WhatsApp"
                  icon={icons[4]}
                />
                <ContactInfo
                  title="Visítanos"
                  text="ARGENTINA - Pago Tienda
              José A. Cabrera 5000, Buenos Aires, Argentina"
                  text2="COLOMBIA - Pago Tienda
              Calle 15 #122-75 Oficina 311, Bogotá, Colombia"
                  additionalInfo="Domingo (12:00 - 22:00) - Lunes a Viernes (8:00 - 20:00) - Sábado (12:00 - 24:00)"
                  icon={icons[6]}
                />
                <ContactInfo
                  title="Llámanos"
                  text="ARGENTINA :+54 9 11 5220-9217"
                  text2="COLOMBIA:  +57 305 3393070"
                  additionalInfo="Un asesor estará feliz de atenderte"
                  icon={icons[5]}
                />
              </div>

              <motion.div
                className="w-full lg:w-[60%] p-[20px] rounded-tl-xl rounded-br-xl space-y-[20px] scale-[1.05]"
                style={{
                  boxShadow: "rgba(99, 11, 134, 0.05) 0px 8px 24px",
                }}
                whileHover={{
                  scale: 1.05,
                  boxShadow: "rgba(99, 11, 134, 0.10) 0px 8px 24px",
                }}
              >
                <h3 className="text-3xl text-main-purple font-semibold">
                  Envíanos un email
                </h3>
                <form
                  target="_blank"
                  action="https://formsubmit.co/mateogooplay@gmail.com"
                  method="POST"
                >
                  <div className="flex flex-col space-y-[20px] ">
                    <label className="flex flex-col text-sm text-black">
                      <span className="text-black">Nombre: </span>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          const value = e.target.value;
                          setName(value);
                        }}
                        className={`${width} p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple`}
                        name="name"
                        required
                      />
                    </label>
                    <label className="flex flex-col text-sm text-black">
                      <span className="text-black">Correo electrónico: </span>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          const value = e.target.value;
                          setEmail(value);
                        }}
                        className={`${width} p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple`}
                        name="email"
                        required
                      />
                    </label>
                    <label className="flex flex-col text-sm text-black">
                      <span className="text-black">Asunto: </span>
                      <input
                        type="text"
                        value={subject}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSubject(value);
                        }}
                        className={`${width} p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple`}
                        name="subject"
                        required
                      />
                    </label>
                    <label className="flex flex-col text-sm text-black">
                      Comentarios adicionales:
                      <textarea
                        className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                        onChange={(e) => setAdditional(e.target.value)}
                        name="comments"
                      />
                    </label>
                    <motion.button
                      type="submit"
                      className="bg-main-purple py-[10px] px-[20px] rounded-tl-xl rounded-br-xl text-white w-full"
                      whileHover={{ backgroundColor: "#44045E" }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Enviar
                    </motion.button>
                  </div>
                </form>
              </motion.div>
            </div>
          ) : !desktopWidth ? (
            <div className="w-full flex flex-col lg:flex-row space-y-[30px] lg:space-y-0 lg:space-x-[30px]">
              <div className="w-full lg:w-[40%] grid grid-cols-2 lg:grid-cols-1 gap-[30px]">
                <ContactInfo
                  title="Hablemos por mensaje!"
                  text="+54 9 11 5220-9217"
                  additionalInfo="WhatsApp"
                  icon={icons[4]}
                />
                <ContactInfo
                  title="Visitanos"
                  text="ARGENTINA - Pago Tienda
              José A. Cabrera 5000, Buenos Aires, Argentina"
                  text2="COLOMBIA - Pago Tienda
              Calle 15 #122-75 Oficina 311, Bogotá, Colombia"
                  additionalInfo="Domingo (12:00 - 22:00) - Lunes a Viernes (8:00 - 20:00) - Sábado (12:00 - 24:00)"
                  icon={icons[6]}
                />
                <ContactInfo
                  title="Llamanos"
                  text="ARGENTINA :+54 9 11 5220-9217"
                  text2="COLOMBIA:  +57 305 3393070"
                  additionalInfo="Un asesor estará feliz de atenderte"
                  icon={icons[5]}
                />
              </div>

              <motion.div
                className="w-full lg:w-[60%] lg:p-[20px] rounded-tl-xl rounded-br-xl space-y-[20px] lg:scale-[1.05]"
                style={
                  desktopWidth
                    ? {
                        boxShadow: "rgba(99, 11, 134, 0.05) 0px 8px 24px",
                      }
                    : { boxShadow: "rgba(99, 11, 134, 0.00) 0px 8px 24px" }
                }
                whileHover={
                  desktopWidth && {
                    scale: 1.05,
                    boxShadow: "rgba(99, 11, 134, 0.10) 0px 8px 24px",
                  }
                }
              >
                <h3 className="text-3xl text-main-purple font-semibold">
                  Envianos un email
                </h3>
                <form
                  target="_blank"
                  action="https://formsubmit.co/mateogooplay@gmail.com"
                  method="POST"
                >
                  <div className="flex flex-col space-y-[20px] ">
                    <label className="flex flex-col text-sm text-black">
                      <span className="text-black">Nombre: </span>
                      <input
                        type="text"
                        value={name}
                        onChange={(e) => {
                          const value = e.target.value;
                          setName(value);
                        }}
                        className={`${width} p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple`}
                        name="name"
                        required
                      />
                    </label>
                    <label className="flex flex-col text-sm text-black">
                      <span className="text-black">Correo electrónico: </span>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => {
                          const value = e.target.value;
                          setEmail(value);
                        }}
                        className={`${width} p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple`}
                        name="email"
                        required
                      />
                    </label>
                    <label className="flex flex-col text-sm text-black">
                      <span className="text-black">Asunto: </span>
                      <input
                        type="text"
                        value={subject}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSubject(value);
                        }}
                        className={`${width} p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple`}
                        name="subject"
                        required
                      />
                    </label>
                    <label className="flex flex-col text-sm text-black">
                      Comentarios adicionales:
                      <textarea
                        className="w-full p-[10px] bg-transparent rounded-lg border-[1px] border-light-gray placeholder:text-light-gray text-black focus-visible:outline-main-purple"
                        onChange={(e) => setAdditional(e.target.value)}
                        name="comments"
                      />
                    </label>
                    <motion.button
                      type="submit"
                      className="bg-main-purple py-[10px] px-[20px] rounded-tl-xl rounded-br-xl text-white w-full"
                      whileHover={{ backgroundColor: "#44045E" }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Enviar
                    </motion.button>
                  </div>
                </form>
              </motion.div>
            </div>
          ) : null}
        </div>
      </div>

      <Footer />
    </motion.div>
  );
}

export default Aliados;
