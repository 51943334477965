import React from "react";
import UseWindowDimensions from "../../utils/UseWindowDimensions";
import Carrousel from "../Carrousel";

function ProductsSection() {
  const prod = ["group1.svg", "group2.svg", "group3.svg"];
  const prodMob = ["group1Mob.svg", "group2Mob.svg", "group3Mob.svg"];
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  return (
    <div className="py-[40px] px-[16px] md:px-[50px] lg:px-[100px] flex flex-col items-center space-y-[60px]  mt-[10px] bg-white">
      <h3 className="text-center text-main-purple text-2xl font-bold md:text-4xl">
        Productos más vendidos
      </h3>
      {desktopWidth ? (
        <Carrousel images={prod} autoPlay duration={3000} isAlone />
      ) : (
        <Carrousel images={prodMob} autoPlay duration={3000} isAlone />
      )}
    </div>
  );
}

export default ProductsSection;
