import React, { useEffect, useState } from "react";
import { IoIosSearch, IoIosArrowUp } from "react-icons/io";
import noImg from "../../assets/images/noImage.svg";
import { motion } from "framer-motion";
import Overlay from "../Overlay";
import AddToCartButton from "../AddToCartButton";
import CheckoutAlert from "../alerts/CheckoutAlert";
import { useCartContext } from "../../context/CartProvider";

function ProfileRestaurantBanner({ store, storeCategories, storeProducts }) {
  const [selectedCategory, setSelectedCategory] = useState([
    storeCategories[0].id,
  ]);
  const [allProducts, setAllProducts] = useState(storeProducts);
  const [quest, setQuest] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { isAlertAdded, setIsAlertAdded } = useCartContext();

  const handleChangeInput = (e) => {
    setQuest(e.target.value);
    filter(e.target.value);
  };

  const filter = (questValue) => {
    let questResponse = allProducts.filter((item) => {
      if (
        item.name.toString().toLowerCase().includes(questValue.toLowerCase()) ||
        item.description
          .toString()
          .toLowerCase()
          .includes(questValue.toLowerCase())
      ) {
        return item;
      }
    });
    setSelectedCategory(questResponse);
  };

  const CategoryCard = () => {
    const isInSelectedCategory = (id) => {
      return selectedCategory.includes(id);
    };

    const toggleSelectedCategory = (id) => {
      if (isInSelectedCategory(id)) {
        setSelectedCategory(
          selectedCategory.filter((category) => category !== id)
        );
      } else {
        setSelectedCategory([...selectedCategory, id]);
      }
    };

    //**Validate if im looking for an item or just display all categories
    if (quest !== "") {
      return (
        <motion.div className="grid grid-cols-2 md:grid-cols-3 gap-[30px]">
          {selectedCategory.map((product) => (
            <div
              className="w-full h-full flex flex-col justify-between"
              key={product.id}
            >
              <div className="h-[60%] w-full max-h-[500px]">
                <img
                  src={product.image ? product.image.url : noImg}
                  alt="PagoTienda"
                  className="w-full h-full"
                />
              </div>
              <div className="h-[35%] w-full flex flex-col justify-between">
                <div className="overflow-y-scroll">
                  <p className="text-black">{product.name}</p>
                  <span className="text-gray text-sm">
                    {product.description}
                  </span>
                  <p className="text-black">
                    {store.currency.code}
                    {product.price}
                  </p>
                </div>
                <div>
                  <AddToCartButton
                    onClick={() => {
                      if (product.stockLevel !== 0) {
                        setSelectedProduct(product);
                      }
                    }}
                    product={product}
                  />
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      );
    } else {
      return storeCategories.map((category) => (
        <div key={category.id}>
          <div
            className="bg-gradient-to-r from-main-purple to-[#640A88] flex justify-between p-[30px] rounded-t-xl my-[30px] items-center cursor-pointer"
            onClick={() => toggleSelectedCategory(category.id)}
          >
            <h3 className="text-white text-4xl">{category.name}</h3>
            <motion.div
              initial={
                isInSelectedCategory(category.id)
                  ? { rotate: 180 }
                  : { rotate: 0 }
              }
              animate={
                isInSelectedCategory(category.id)
                  ? { rotate: 0 }
                  : { rotate: 180 }
              }
            >
              <IoIosArrowUp className="text-white text-4xl" />
            </motion.div>
          </div>
          <motion.div
            className={`grid grid-cols-2 md:grid-cols-3 gap-[30px] ${
              isInSelectedCategory(category.id)
                ? "max-h-full"
                : "overflow-hidden max-h-0"
            }`}
          >
            {category.products.map((product) => (
              <div
                className="w-full h-full flex flex-col justify-between space-y-[16px]"
                key={product.id}
              >
                <div className="h-[60%] w-full ">
                  <img
                    src={product.image ? product.image.url : noImg}
                    alt="PagoTienda"
                    className="w-full h-full max-h-[500px] object-cover aspect-square"
                  />
                </div>
                <div className="h-[40%] w-full flex flex-col justify-between ">
                  <div
                    className="overflow-y-scroll"
                    style={{ scrollPadding: "20px" }}
                  >
                    <p className="text-black">{product.name}</p>
                    <span className="text-gray text-sm">
                      {product.description}
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-black">
                    {store.currency.code}
                    {product.price}
                  </p>
                  <AddToCartButton
                    onClick={() => {
                      if (product.stockLevel !== 0) {
                        setSelectedProduct(product);
                      }
                    }}
                    product={product}
                  />
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      ));
    }
  };

  return (
    <div>
      <div className="w-full h-full space-y-[20px] flex flex-col lg:flex-row lg:space-y-0 lg:space-x-[20px]">
        <div className="h-[50%] w-full bg-red-gray lg:w-[30%] lg:h-full">
          <img alt={store.banner.title} src={store.banner.url} />
        </div>
        <div className="h-[50%] w-full space-y-[20px] lg:w-[70%] lg:h-full flex flex-col">
          <div className=" flex justify-start flex-col items-center lg:items-start">
            <p className="text-xl text-black lg:text-4xl font-semibold">
              {store.name.toUpperCase()}
            </p>
            <span className="text-gray text-sm lg:text-base">{store.slug}</span>
          </div>
          <div className="space-y-[10px]">
            <p className="text-gray">
              📍 {store.address.line_one}, {store.address.line_two}.
            </p>
            <p className="text-gray">⏱️ {store.description}</p>
          </div>
        </div>
      </div>
      <div className="border-y-[5px] border-[#f7f8f9] w-full mt-[20px] p-[10px] space-y-[10px] flex flex-col items-center lg:flex-row lg:space-y-0">
        <p className="lg:w-[30%] text-black">¿Buscas algo en especifico?</p>
        <div
          className=" 
        flex items-center relative border-[1px] border-black rounded-3xl w-full lg:w-[70%]"
        >
          <IoIosSearch className="absolute left-[5px] text-black" size="20" />
          <input
            className="h-full flex-1 rounded-3xl px-[30px] focus-visible:outline-main-purple py-[5px] placeholder:text-light-gray placeholder:text-sm"
            placeholder="Busqueda por nombre de producto"
            value={quest}
            onChange={handleChangeInput}
          />
        </div>
      </div>
      <CategoryCard />
      <Overlay selected={selectedProduct} setSelected={setSelectedProduct} />
      <CheckoutAlert
        setIsAlertOpened={setIsAlertAdded}
        isAlertOpened={isAlertAdded}
      />
    </div>
  );
}

export default ProfileRestaurantBanner;
