import React from "react";
import UseWindowDimensions from "../../../utils/UseWindowDimensions";
import InstructionCard from "./InstructionCard";
import bgTablet from "../../../assets/images/insBg.svg";
import bgMobile from "../../../assets/images/insBgMobile.svg";
import { InstructionsInfo } from "../../../utils/InstructionsInfo";
import { motion } from "framer-motion";

function InstructionsSection() {
  const { height, width } = UseWindowDimensions();
  const desktopWidth = width >= 764;
  const divAnimation = {
    offScreen: { y: 100, opacity: 0 },
    onScreen: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.4, damping: 50 },
    },
  };

  return (
    <motion.div
      style={
        desktopWidth
          ? {
              backgroundImage: `url(${bgTablet}) `,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
          : {
              backgroundImage: `url(${bgMobile}) `,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }
      }
      className="py-[50px] px-[16px] md:px-[50px] lg:px-[100px] grid md:grid-cols-3 gap-[100px]"
      initial={"offScreen"}
      whileInView={"onScreen"}
      viewport={
        desktopWidth
          ? { once: false, amount: 0.5 }
          : { once: false, amount: 0.1 }
      }
      transition={{ staggerChildren: 0.2 }}
    >
      {InstructionsInfo.map((instruction, index) => (
        <motion.div variants={divAnimation} key={index}>
          <InstructionCard
            title={instruction.title}
            description={instruction.description}
            icon={instruction.icon}
          />
        </motion.div>
      ))}
    </motion.div>
  );
}

export default InstructionsSection;
